<template>
    <loading v-if='loading'/>
    <div v-else :class="['group-tile', selected?'selected':'']" @click="selectGroup">
        <div class="tile-text text-wrap">
            {{ groupName }}
        </div>

    </div>
</template>

<script>
import Loading from '@/components/loading'
import {mapState, mapActions} from 'vuex'

export default{
    props: {
        groupId: {
            default: null
        },
        selected: {
            type: Boolean,
            default: false
        }

    },
    components:{
        Loading
    },
    computed: {
        ...mapState({

            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupStatus: state => state.groupStore.status,
            groupsByActivity: state => state.groupStore.groupsByActivity,
            groupsBySession:        state => state.groupStore.groupsBySession,
            groupRoleLists: state => state.groupStore.groupRoleLists,
            availablePlayersByActivity: state => state.groupStore.availableByActivity,
        }),
        loading(){
            return false ||
                false;
        },
        groupName(){
            return this.groups[this.groupId].name.replace(/\//g, "/\u200B")

        }

    },
    methods: {
        ...mapActions('navigatorStore', {
            filterPlayers: 'filterPlayers',
            setSelectedParticipant: 'setSelectedParticipant'
        }),
        selectGroup(){
            this.setSelectedParticipant(this.groupId)
            this.$root.$emit('close-selection-dialog')
        }

    },
    watch: {},
    async created(){

    },
    data(){
        return {

        }
    }

}
</script>

<style scoped>
.group-tile {
    border-style: solid;
    border-radius: 5px;
    padding: 5px;

}

.tile-text {
    font-family: "Varela Round";
    font-size: 12px;
}

.selected{
    background-color: greenyellow;
    color: black;
    

}

</style>
<template>
    <loading v-if='loading'/>
    <div class="group-selector" v-else >
        <v-row style="margin-top:auto;margin-bottom:auto">
            <div v-if="groupList.length===0" class="no-groups-message">
                No groups are available to select. You can create new groups by clicking on the <v-icon color="grey">mdi-account-multiple-plus</v-icon> icon to the right...
            </div>
            <v-col :cols="tileWidth" v-for="groupId in groupsBySession[0]" :key="groupId" @click="clickOnTile(groupId)">
                <MiniGroupTile :groupId="groupId" @click="clickOnTile(groupId)" :selected="isSelected(groupId)"/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import MiniGroupTile from '@/components/MiniGroupTile'

import {mapState} from 'vuex'
import { mapActions } from 'vuex/dist/vuex.common.js';

export default{
    props: [],
    components:{
        Loading,
        MiniGroupTile,
    },
    computed: {
        ...mapState({
            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupStatus: state => state.groupStore.status,
            groupsByActivity: state => state.groupStore.groupsByActivity,
            groupsBySession:        state => state.groupStore.groupsBySession,
            groupRoleLists: state => state.groupStore.groupRoleLists,
            availablePlayersByActivity: state => state.groupStore.availableByActivity, 

            selectionList:          state => state.navigatorStore.selectionList,
            allowMultipleSelection: state => state.navigatorStore.allowMultipleSelection,
        }),
        tileWidth(){
            return this.miniScreen?12:2
        },
        loading(){
            return false ||
                false;
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        },

    },
    methods: {
        ...mapActions('navigatorStore', [
            'addToSelected',
            'removeFromSelected',
            'resetSelected',
        ]),
        clickOnTile(groupId){
            console.log('clickOnTile')
            if(this.selectionList.includes(groupId)){
                console.log(`Removing ${groupId}`)
                this.removeFromSelected(groupId)
            } else{
                console.log(`Selecting ${groupId}`)
                this.addToSelected(groupId)  
            }
        },
        isSelected(groupId){
            if(this.selectionList.includes(groupId)){
                return true
            }
            return false
        }

    },
    watch: {},
    async created(){

    },
    data(){
        return {
        }
    }

}
</script>

<style scoped>
.group-selector{
    flex-grow:1;
    margin-top:auto;
    margin-bottom:auto;
}

.no-groups-message{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: large;
    padding-top: 20px;
    font-style:italic;;
}
</style>
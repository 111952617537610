export const rc_sessionCheckin = {
    USER_CHECKED_IN         : 10001,
    NO_PARTICIPANT_FOR_USER : 10002,
    NO_PARTICIPANT_FOUND    : 10003,
    USER_ALREADY_LINKED     : 10004,
}
export const rc_participants = {
    PARTICIPANT_FOUND       : 11000,
    NO_MATCH                : 11001,
    CREATE_FAILED           : 11002,
    PARTICIPANT_NUMBER_USED : 11003,
}

export const rc_qresponses = {
    FOUND                   : 12000,
    NOT_FOUND               : 12001,
    ALREADY_EXISTS          : 12002,
    CREATED                 : 12003,
}

export const rc_auth = {
    USER_UPDATED            : 13000,
    EMAIL_EXISTS            : 13001,
    NO_USER_FOUND           : 13002,
    OTP_SENT                : 13003,
    BAD_RECAPTCHA           : 13004,
    NEED_RECAPTCHA          : 13005,
    OTP_OK                  : 13006,
    OTP_ERROR               : 13007,
    LOGIN_FAILED            : 13008,
    LOGIN_OK                : 13009,
    EMAIL_CHANGE_NA         : 13010,
    NO_PARTICIPANT_FOUND    : 13011,
    INVALID_EMAIL           : 13012,
}

export const rc_feedback = {
    OK                      : 14000,
    EVENT_NOT_FOUND         : 14001,
    CRITERION_NOT_FOUND     : 14002,
    OUT_OF_RANGE            : 14003,
    NOT_FOUND               : 14004,
    
}
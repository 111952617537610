<template>
    <v-row v-if="!loading" width="100%" :id="updateId" :key="updateId" class="no-boundaries">
        <v-col style="margin: auto 0px auto 0px;padding:0px;">
            <div v-if="nSmartFilter && objectiveRemoved>0">
                {{ objectiveRemoved }} trialists hidden by AI Filtering
            </div>
            <v-btn-toggle v-if="displayList.length>0" v-model="playerToggle" mandatory dense tile >
                <player-select-button 
                        v-for="participantId in displayList" 
                        :key="participantId" 
                        v-on:click.native="updatePlayer(participantId)" 
                        :participantId="participantId" 
                        :showNames="showNames" 
                        :sortByFirstName="sortByFirstName"    
                        />
            </v-btn-toggle>
            <div v-else style="font-family:'Varela Round';font-size:medium">
                No trialists to show with current filters
            </div>
        </v-col>
        <v-col cols="2" class="filter-column no-boundaries">
            <v-row>
                <ParticipantFilterDialog />

            </v-row>
            <v-row style="margin:0px;padding:0px">
                <v-switch v-model="smartFilter" label="Objective Filtering" >
                    <template v-slot:label>
                        <span style="font-size:x-small">AI Filter</span>
                    </template>
                </v-switch>

            </v-row>

            <v-row style="margin:0;padding:0px">
                <v-switch style="padding:0px" v-model="showNames" label="Show names on buttons">
                    <template v-slot:label>
                        <span style="font-size:x-small">Show names</span>
                    </template>
                </v-switch>
            </v-row>
            <v-row style="margin:0;padding:0px">
                        <v-select dense v-model="sortByFirstName" item-text="text" item-value="value" :items="sortOptions" label="Sort by"/>

            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PlayerSelectButton from './PlayerSelectButton.vue';
//eslint-disable-next-line
import ParticipantFilterDialog from './ParticipantFilterDialog.vue';
import { v4 as uuidv4} from 'uuid'
export default {
    
    components: {
    PlayerSelectButton,
    //eslint-disable-next-line
    ParticipantFilterDialog
},
    computed:{
        ...mapState({
            participants:  state => state.participantStore.data,
            participantList: state => state.participantStore.list,
            participantsByFirstName: state => state.participantStore.listByFirstName,
            participantsByLastName: state => state.participantStore.listByLastName,
            participantStatus: state => state.participantStore.status,
            displayList: state => state.participantStore.displayList,

            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,

            roles: state => state.roleStore.data,
            roleList: state => state.roleStore.list,
            roleStatus: state => state.roleStore.status,

            sessions: state => state.sessionStore.data,
            sessionList:   state => state.sessionStore.list,
            sessionStatus: state => state.sessionStore.status,

            teams: state => state.teamStore.data,
            teamList: state => state.teamStore.list,
            teamStatus: state => state.teamStore.status,


            selectedTryout: state => state.navigatorStore.selectedTryout,

            nShowNames: state => state.navigatorStore.showNames,
            nShowBypassed: state => state.navigatorStore.showBypassed,
            nSelectedTeams: state => state.navigatorStore.selectedTeams,
            nSelectedRoles: state => state.navigatorStore.selectedRoles,
            nSortByFirstName: state => state.navigatorStore.sortByFirstName,
            nShowTeamUnassigned: state => state.navigatorStore.showTeamUnassigned,
            nShowRoleUnassigned: state => state.navigatorStore.showRoleUnassigned,
            selectedParticipant: state => state.navigatorStore.selectedParticipant,
            nSmartFilter: state => state.navigatorStore.smartFilter,
            objectiveRemoved: state => state.navigatorStore.objectiveRemoved,
            selectionList:          state => state.navigatorStore.selectionList,

        }),
        smartFilter: {
            get(){
                return this.nSmartFilter
            },
            set(value){
                this.setSmartFilter(value)
            }
        },
        
        selectedPlayer: {
            get(){
                return this.nSelectedParticipant
            },
            set(value){
                this.setSelectedParticipant(value)
            }
        },
        showBypassed: {
            get(){
                return this.nShowBypassed
            },
            set(value){
                this.setShowBypassed(value)
            }
        },
        sortByFirstName: {
            get(){
                return this.nSortByFirstName 
            },
            set(value){
                this.setSortByFirstName(value)
            }
        },
        loading(){
            return false
                || this.participantStatus.loading
                || this.tryoutStatus.loading
                || this.roleStatus.loading
                || this.sessionStatus.loading
                || this.teamStatus.loading

        },
        bypassCurrent: {
            get() {
                if(this.selectedPlayer === 0) return this.participants[this.participantList[0].bypassCollection]
                return this.participants[this.selectedPlayer].bypassCollection
            },
            set(value){
                this.updateParticipantProp({field: 'bypassCollection', id: this.selectedPlayer, value: value}) 
            }
        },
        showNames:{
            get(){
                return !(!this.nShowNames) 
            },
            set(value){
                this.$store.commit('navigatorStore/setShowNames', value)
            }
        },

    },
    methods: {
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            quietFindAllParticipantsForEvent: 'quietFindAllForEvent',
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
        }),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant',
            'setShowBypassed',
            'setSortByFirstName',
            'setSmartFilter',
            'addToSelected',
            'removeFromSelected',
            'resetSelected',
        ]),
        ...mapActions('attendanceStore', {
            findAllAttendanceForEvent: 'findAllForEvent',
            quietFindAllAttendanceForEvent: 'quietFindAllForEvent',
        }),
        clickOnTile(participantId){
            console.log('clickOnTile')
            if(this.selectionList.includes(participantId)){
                console.log(`Removing ${participantId}`)
                this.removeFromSelected(participantId)
            } else{
                console.log(`Selecting ${participantId}`)
                this.addToSelected(participantId)  
            }
        },
        updatePlayer(playerId){
            this.selectedPlayer = playerId
            // Also add the player or remove from the selection list...

            this.clickOnTile(playerId)

        },
        closeFilterDialog(){
            this.filterDialog=false
        },
        async bypass(){
           this.updateParticipantProp({field: 'bypassCollection', id: this.selectedPlayer, value: true}) 
           await this.updateParticipant(this.participants[this.selectedPlayer])
           this.selectedPlayer = this.displayList[0]
        },
        async unbypass(){
           this.updateParticipantProp({field: 'bypassCollection', id: this.selectedPlayer, value: false}) 
           await this.updateParticipant(this.participants[this.selectedPlayer])
           this.selectedPlayer = this.displayList[0]

        },
        async refresh(){
//            await this.quietFindAllAttendanceForEvent({eventId: this.selectedTryout})
            this.availablePlayers = [...this.participantList]
            if(this.nShowNames){
                this.sortOptions=[{text:'Numbers', value:0}, 
                                {text:'First Name',value:1},
                                {text:'Last Name', value:2}]
                this.sortByFirstName=1
            } else {
                this.sortOptions=[{text:'Numbers', value:0}]
                this.sortByFirstName=0
            }
        }
    },
    sockets: {
        async 'UpdatePlayerAttendance'(){
            this.$nextTick(async ()=>{
                const self=this
                await self.refresh()
                self.updateId = uuidv4()

            })
        }
    },

    created(){
        this.updateId = uuidv4()
        this.refresh()
    },
    watch: {
        nShowNames(){

            if(this.nShowNames){
                this.sortOptions=[{text:'Numbers', value:0}, 
                                  {text:'First Name',value:1},
                                  {text:'Last Name', value:2}]
                this.sortByFirstName=1
            } else {
                this.sortOptions=[{text:'Numbers', value:0}]
                this.sortByFirstName=0
            }
        }
    },
    data(){
        return {
            updateId:'0',
            playerToggle: 0,
            selectedTeams: [],
            showTeamUnassigned: 1,
            selectedRoles: [],
            showRoleUnassigned:1,
            filterDialog: 0,
            sortOptions: [{text:'Number', value:0}],
            selectedSort: 0,

        }
    }
}
</script>

<style scoped>

.v-btn-toggle{
    flex-direction: row;
    flex-wrap: wrap;
}
.v-btn--active{
    border-color: blue;
    border-style: solid;
    border-radius: 5px;
    border-width: 10px;
}

.filter-column{

    margin:0px;
    margin-top:10px;
}

</style>
<template>
    <v-card elevation="5" rounded class="ma-10 pa-10">
        <ValidationObserver v-slot="{invalid}">
            <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <div style="display: flex; flex-flow:row;">
                            <img height="50" :src="clubs[selectedClub].logo"/>
                            <div>

                                <div style="text-align:center">
                                    <h3>{{ events[selectedEvent].title }}</h3>
                                </div>
                                <div>
                                    {{ seasons[events[selectedEvent].seasonId].name }}
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h3>
                            New {{ $capitalize(events[selectedEvent].episodeFormName) }}
                        </h3>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6" sm="6" md="6" lg="4" xl="4" >
                        <v-text-field prepend-icon="mdi-clipboard-list-outline" label="Coach" readonly v-model="coachName" :tabIndex="0"/>
                    </v-col>
                </v-row>
                <v-row> 
                    <v-col cols="6" sm="6" md="6" lg="3" xl="3">
                        <v-menu
                            v-model="showDate"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class="date-menu"
                        >
                            <template v-slot:activator="{ on,attrs }">
                                <v-text-field
                                    v-model="startDate"
                                    :label="$capitalize(events[selectedEvent].eventNoun) +' Date'"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[rules.required]"
                                    :tabIndex="0"
                                />
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                @input="showDate=false"
                                :tabIndex="0"
                            />
                        </v-menu> 
                    </v-col>
                    <v-col colr="6" sm="6" md="6" lg="3" xl="3">
                        <v-menu
                            ref="stmenu"
                            v-model="stmenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="startTime"
                                label="Evaluation Time"
                                prepend-icon="mdi-clock-time-four-outline"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :tabIndex="0"
                            ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="stmenu"
                                v-model="startTime"
                                full-width
                                format="24hr"
                            ></v-time-picker>
                        </v-menu>

                    </v-col>
                    <v-col colr="6" sm="6" md="6" lg="4" xl="4">
                    </v-col>
                </v-row>
            


                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-row class="no-boundaries">
                            <v-col cols="11" sm="11" md="11"    lg="5" xl="5" class="no-boundaries">
                                <v-select 
                                    prepend-icon="mdi-whistle-outline" 
                                    :label="'Available '+ $capitalize($pluralize(events[selectedEvent].participantPronoun))"
                                    :items="activeParticipantsList" 
                                    v-model="selectedParticipant"

                                    :tabIndex="0"
                                >
                                    <template v-slot:selection="{item}">
                                        {{ participants[item].firstName }} {{  participants[item].lastName }}
                                    </template>
                                    <template v-slot:item="{item}">
                                        {{ participants[item].firstName }} {{  participants[item].lastName }}
                                    </template>
                                </v-select>

                            </v-col>
                            <v-col cols="1" style="margin-top:0px" class="no-boundaries">
                                <v-btn :disabled="!selectedParticipant" @click="addParticipant" x-small color="primary">Add</v-btn>
                            </v-col>
                        </v-row>
                        
                        <v-row class="no-boundaries">
                            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="no-boundaries">
                                <BoundedBox title="$capitalize($pluralize(events[selectedEvent].participantPronoun))" borderWidth="1px" titleSize="0.8em">
                                    <span slot="title">
                                        Selected {{ $capitalize($pluralize(events[selectedEvent].participantPronoun)) }}
                                    </span>
                                    <div slot="content" >
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th >
                                                    Name
                                                </th>
                                                <th >
                                                    Role
                                                </th>
                                                <th class="text-right">
                                                    Actions
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item,n) in selectedParticipantList" :key="item.participantId" >
                                                    <td class="text-left">{{ participants[item.participantId].firstName}} {{ participants[item.participantId].lastName }}</td>
                                                    <td class="text-left">
                                                        <v-select :items="roleList" v-model="selectedParticipantList[n].role" :tabIndex="0">
                                                            <template v-slot:selection="{item}">
                                                                {{ roles[item].name }}
                                                            </template>
                                                            <template v-slot:item="{item}">
                                                                {{ roles[item].name }}
                                                            </template>
                                                        </v-select>
                                                    </td>
                                                    <td class="text-right"><v-icon color="red" small @click="deleteParticipant(n)">mdi-delete</v-icon></td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                        <div v-if="selectedParticipantList.length===0">
                                            No {{ $pluralize(events[selectedEvent].participantPronoun)}} selected. 
                                        </div>
                                    </div>
                                </BoundedBox>
                            </v-col>
                        </v-row>

                    </v-col>
                    <v-col v-if="false" cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-row class="no-boundaries">
                            <v-col cols="11" sm="11" md="11"    lg="5" xl="5" class="no-boundaries">
                                <v-select 
                                    prepend-icon="mdi-whistle-outline" 
                                    label="Available Coaches" 
                                    :items="activeCoachList" 
                                    v-model="selectedStaff"
                                    :tabIndex="0"
                                >
                                    <template v-slot:selection="{item}">
                                        {{ staff[item].firstName }} {{  staff[item].lastName }}
                                    </template>
                                    <template v-slot:item="{item}">
                                        {{ staff[item].firstName }} {{  staff[item].lastName }}
                                    </template>
                                </v-select>

                            </v-col>
                            <v-col cols="1" style="margin-top:0px" class="no-boundaries">
                                <v-btn :disabled="!selectedStaff" @click="addCoach" x-small color="primary">Add</v-btn>
                            </v-col>
                        </v-row>
                        <v-row class="no-boundaries">
                            <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="no-boundaries">
                                <BoundedBox title="Additional Coaches" borderWidth="1px" titleSize="0.8em">
                                    <span slot="title">
                                        Selected Coaches
                                    </span>
                                    <div slot="content" >
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th >
                                                    Name
                                                </th>
                                                <th class="text-right">
                                                    Actions
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item,n) in selectedStaffList" :key="item.userId" >
                                                    <td class="text-left">{{ staff[item.userId].firstName}} {{ staff[item.userId].lastName }}</td>
                                                    <td class="text-right"><v-icon color="red" small @click="deleteParticipant(n)">mdi-delete</v-icon></td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                        <div v-if="selectedStaffList.length===0">
                                            No additional coaches selected. 
                                        </div>
                                    </div>
                                </BoundedBox>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
                <!-- Now render the questions -->
                <v-row>
                    <v-col v-for="questionId in questionList" :key="questionId" cols="6" sm="6" md="6" lg="6" xl="6">
                        <QuestionRender :qid="questionId" :value="response[questionId]" />
                    </v-col>
                </v-row>

            </v-card-text>
                <v-divider/>
            <v-card-actions style="margin-top:10px;">
                <v-btn small text color="primary" :tabIndex="0">Cancel</v-btn>
                <v-spacer/>
                <v-btn :disabled="invalidData || invalid" small color="primary" @click="saveNewEpisode" :tabIndex="0">Save and Continue</v-btn>

            </v-card-actions>
        </ValidationObserver>
    </v-card>
</template>

<script>


import {rules} from '@/_helpers/rules'
import {mapState, mapActions} from 'vuex'
import BoundedBox from '@/components/BoundedBox'
import {ValidationObserver} from 'vee-validate'
import QuestionRender from '@/components/QuestionRender'

export default{
    props: [],
    components:{
        BoundedBox,
        ValidationObserver,
        QuestionRender,
    },
    computed: {
        ...mapState({

            events:             state => state.tryoutStore.data,
            seasons:            state => state.seasonStore.data,
            participants:       state => state.participantStore.data,
            participantList:    state => state.participantStore.listByFirstName,
            roles:              state => state.roleStore.data,
            roleList:           state => state.roleStore.list,
            clubs:              state => state.clubStore.data,
            staff:              state => state.staffStore.data,
            staffList:          state => state.staffStore.list,

            questions:          state => state.appquestionStore.data,
            questionListByContext:       state => state.appquestionStore.listByContext,
            fullQuestionList:   state => state.appquestionStore.list,
            
            selectedEvent:      state => state.navigatorStore.selectedTryout,
            selectedClub:       state => state.navigatorStore.selectedClub,
            selectedSeason:     state => state.navigatorStore.selectedSeason,

            account:            state => state.account.user.details
        }),
        loading(){
            return false ||
                false;
        },
        questionList(){
            return this.questionListByContext['devform']
        },
        coachName(){
            return `${this.account.firstName} ${this.account.lastName}`
        },
        activeParticipantsList(){
            // Exclude anyont in the selected list
            return this.participantList.filter((item)=>{
                for(var i=0;i< this.selectedParticipantList.length;i++){
                    if(this.selectedParticipantList[i].participantId === item) return false
                }
                return true
            })
        },
        activeCoachList(){
            // Exclude anyont in the selected list
            return this.staffList.filter((item)=>{
                for(var i=0;i< this.selectedStaffList.length;i++){
                    if(this.selectedStaffList[i].userId === item) return false
                }
                return true
            })
        },

        invalidData(){
            if(this.listLength===0){
                return true
            } 
            for(var i=0;i<this.selectedParticipantList.length;i++){
                if(this.selectedParticipantList[i].role===null) {
                    return true
                }
            }
            return false
        },
        listLength(){
            return this.selectedParticipantList.length
        },
        startDates: {
            get(){
                return this.startDateTime.toISOString().split('T')[0]
            },
            set(newValue){
                const date = newValue.split('-')
                const baseDate = this.startDateTime
                baseDate.setYear(date[0])
                baseDate.setMonth(date[1]-1)
                baseDate.setDate(date[2])
                this.startDateTime = baseDate
            }
        },

    },
    methods: {
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent',
        ]),
        ...mapActions('episodeStore', [
            'createEpisode',
        ]),
        ...mapActions('qresponseStore', [
            'addQResponse'
        ]),
        ...mapActions('navigatorStore', [
            'selectEpisode',
            'selectClubPane'
        ]),
        ...mapActions('staffStore', [
            'findAllStaffForEvent',
        ]),
        saveStartTime(time){
            this.startTime=time
            this.stmenu=false
        },
        addParticipant(){
            if(this.selectedParticipant){
                this.selectedParticipantList.push({participantId: this.selectedParticipant, role: null})
                this.selectedParticipant=null
            }
        },
        addCoach(){
            if(this.selectedStaff){
                this.selectedStaffList.push({userId: this.selectedStaff, role: null})
                this.selectedStaff=null
            }
        },

        deleteParticipant(n){
            this.selectedParticipantList.splice(n,1)
        },
        async saveNewEpisode(){
            try{
                // Create the datetime

                const dateArray = this.startDate.split('-')
                const timeArray = this.startTime.split(':')
                const newDateTime = new Date(dateArray[0], dateArray[1]-1, dateArray[2], timeArray[0], timeArray[1])

                // Convert the participantID's to userId's

                for(var i=0;i<this.selectedParticipantList.length;i++){
                    const userId = this.participants[this.selectedParticipantList[i].participantId].userId
                    this.selectedParticipantList[i].userId = userId
                }


                // Create the new episode

                const episode = await this.createEpisode({
                    eventId: this.selectedEvent,
                    date: newDateTime.toISOString(),
                    participantList: this.selectedParticipantList,
                    staffList: [{userId: this.account.id}, ...this.selectedStaffList],
                    seasonId: this.selectedSeason,
                })


                // Save the responses to the episode questions


                for(var j in this.fullQuestionList){
                    if(this.questions[this.fullQuestionList[j]].context==='devform'){
                        await this.addQResponse({
                            participantId: episode.id,
                            eventId: episode.id,
                            questionId: this.fullQuestionList[j],
                            response: this.response[this.fullQuestionList[j]]
                        }) 
                    }
                }

                this.selectEpisode(episode.id)
                this.selectClubPane('DevStreamEpisode')
            } catch(error) {
                console.error(error)
            }
        },
        getNextTab(reset=false){
            if(reset) this.taborder=0
            return this.taborder++
        },
    },
    watch: {},
    mounted(){
        this.$root.$on('changed-question-response', (response)=> {
            var self=this
            self.response[response.qid] = response.value
        })
    },
    async created(){
           // this.startTime= new Date().toLocaleTimeString(this.events[this.selectedEvent].localLanguage, {hour12:false}),
        await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})
        await this.loadAllRolesForEvent({eventId: this.selectedEvent})
        await this.findAllStaffForEvent({eventId: this.selectedEvent})

        console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
        await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})
        for(var i=0;i<this.questionListByContext['devform'].length;i++){
            /*
            const questionId=this.questionListByContext['devform'][i]
            if(this.questions[questionId].type==='select')
                this.response[this.questionListByContext['devform'][i]]=-1

            else
            */
                this.response[this.questionListByContext['devform'][i]]=null
        }
    },
    data(){
        return {
            response: {}, 
            rules: rules,
            showDate: false,
//            startDate: new Date().toISOString().split('T')[0],
            startDate: new Date().toLocaleDateString('sv'),
            //startTime: new Date().toISOString().split('T')[1].slice(0,5),
            startTime: new Date().toLocaleTimeString('sv', {hour12:false}),
            sdmenu: false,
            edmenu: false,
            stmenu: false,
            etmenu: false,
            selectedParticipantList: [],
//            staffList: [],
            selectedParticipant: null,
            selectedRoles: {},
            selectedStaffList: [],
            selectedStaff: null,
            taborder:0,
        }
    }

}
</script>
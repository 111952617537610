<template>
    <loading v-if='loading'/>
    <div v-else :style="athleteRowStyle">
        <!--
        <div style="display:flex;flex-grow:1;flex-flow:column;margin-top:auto;margin-bottom:auto;">
        -->
        <div :class="['tab-col', miniScreen?'mobile':'']">
        
            <div :class="['mode-tab', 'individual', context==='individual'?'selected':'', miniScreen?'mobile':'']" @click="selectIndividual">
                <div v-if="miniScreen">
                    {{ $acronize($capitalize($pluralize(events[selectedEvent].participantPronoun))) }}
                </div>
                <div v-else>
                    {{ $capitalize($pluralize(events[selectedEvent].participantPronoun)) }}
                </div>
            </div>
            <div :class="['mode-tab', 'group', context==='group'?'selected':'', miniScreen?'mobile':'']" @click="selectGroup">
                <div v-if="miniScreen">
                    {{ $acronize($capitalize($pluralize(events[selectedEvent].groupPronoun))) }}
                </div>
                <div v-else >
                    {{ $capitalize($pluralize(events[selectedEvent].groupPronoun)) }}
                </div>
            </div>
        </div>
        <div v-if="context==='individual'" :class="['selector-row', miniScreen?'mobile':''] ">
            <div :class="['athlete-card',  miniScreen?'mobile':'']">
                <athlete-card :clickFn="showSelectionDialog" :participantId="selectedPlayer" size="100px"/>
            </div>
            <div class="selector-grid" v-if="miniScreen">
                <v-dialog v-model="selectDialog" width="auto">
                    <v-card>
                        <v-card-title>
                            <GroupSelectorPanel v-if="showGroupSelector" :initialPlayer="selectedPlayer" :activityId="selectedActivity"/>
                            <NormalSelectorPanelMobile v-else :activityId="selectedActivity" />
                        </v-card-title>
                    </v-card>
                </v-dialog>
            </div>
            <div class="selector-grid" v-else>
                <GroupSelectorPanel v-if="showGroupSelector" :initialPlayer="selectedPlayer" :activityId="selectedActivity"/>
                <NormalSelectorPanel v-else :activityId="selectedActivity" />

            </div>
        </div>
        <div v-if="context==='group'" :class="['group-selector','selector-row',miniScreen?'mobile':'']">
            <GroupCard :clickFn="showSelectionDialog" :participantId="selectedPlayer"/>
            <GroupSelectorPanel2 v-if="!miniScreen"/>
            <GroupFilterPanel v-if="!miniScreen"/>
            <v-dialog v-else v-model="selectDialog" width="auto">
                <v-card>
                    <v-card-title>
                        <GroupFilterPanel/>
                        <GroupSelectorPanel2/>
                    </v-card-title>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/loading'

import AthleteCard from '@/components/NewAthleteCard'
import GroupSelectorPanel from '@/components/GroupSelectorPanel.vue';
import NormalSelectorPanel from '@/components/NormalSelectorPanel.vue';
import NormalSelectorPanelMobile from '@/components/NormalSelectorPanelMobile.vue';

import GroupCard from '@/components/GroupCard'
import GroupSelectorPanel2 from './GroupSelectorPanel2.vue';
import GroupFilterPanel from './GroupFilterPanel.vue';

import {mapState, mapActions} from 'vuex'

export default{
    props: ['showGroupSelector', 'selectedActivity'],
    components:{
        Loading,
        AthleteCard,
        GroupSelectorPanel,
        NormalSelectorPanel,
        NormalSelectorPanelMobile,

        GroupCard,
        GroupSelectorPanel2,
        GroupFilterPanel,
    },
    computed: {
        ...mapState({
            selectedParticipant: state => state.navigatorStore.selectedParticipant,
            groupList:              state => state.groupStore.list,
            groups:                 state => state.groupStore.data,
            participantList:        state => state.participantStore.list,
            participants:           state => state.participantStore.data,
            selectedEvent:          state => state.navigatorStore.selectedTryout,

            events:                 state => state.tryoutStore.data,

        }),
        loading(){
            return false ||
                false;
        },
        athleteRowStyle(){
            const base="display:flex;flex-flow:row;width:100%"
            if(this.miniScreen) return base+'flex-grow:1;width:100%'
            return base
        },
        athleteCardStyle(){
            const base="min-width: 150px;"
            if(this.miniScreen) return base+'width:100%'
            return base
        },
        selectedPlayer: {
            get(){
                return this.selectedParticipant
            },
            set(value){
                this.setSelectedParticipant(value)
            }   
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        },
        context(){
            if(this.selectedParticipant === 'nogroup') {
                return 'group'
            }
            if(this.participantList.includes(this.selectedParticipant)){
                return 'individual'
            } else if (this.groupList.includes(this.selectedParticipant)){
                return 'group'
            } else {
                return 'individual'
            }
        }

    },
    methods: {
        ...mapActions('navigatorStore', [
            'setSelectedParticipant',
            'resetSelected'
        ]),
        showSelectionDialog(){
            this.selectDialog=true
        },
        selectIndividual(){
            this.resetSelected()
            this.setSelectedParticipant(this.participantList.filter(id => !(this.participants[id].archived || (this.showBypassed ? false:this.participants[id].bypassCollection)))[0])
        },
        selectGroup(){
            this.resetSelected()
            if(this.groupList.length>0)
                this.setSelectedParticipant(this.groupList.filter(id => !(this.groups[id].archived ))[0])
            else
                this.setSelectedParticipant('nogroup')
        }

    },
    watch: {},
    mounted() {
        this.$root.$on('close-selection-dialog', ()=> {
            var self=this
            self.selectDialog=false
        })
    },
    beforeDestroy(){
        this.$root.$off('close-selection-dialog')
    },
    async created(){

    },
    data(){
        return {
            selectDialog: false,
//            context: 'individual',

        }
    }

}
</script>

<style scoped>
.group-selector{
    width:100%
}
.selector-row{
    display: flex;
    flex-flow: row;
    flex-grow:1;
}
.selector-row.mobile{
    flex-grow:1
}

.tab-col{
    display: flex;
    flex-flow: column;
    margin-top:auto;
    margin-bottom:auto;
}

.tab-col.mobile{
    flex-grow:0;
}

.mode-tab{
    width:30px;
    writing-mode:vertical-rl;
    text-orientation: sideways;
    color:white;
    font-weight:600;
    height:100px;
    opacity:0.2;
    background-color: purple;
    border-top-left-radius:10px;
    border-bottom-left-radius:10px;
}
.mode-tab.individual{
}
.mode-tab.group{
    
}
.mode-tab.mobile{
    height:50px;
}

.mode-tab-text.mobile{
    visibility:hidden;
    display:block;
}

.mode-tab-text.mobile:first-letter{
    visibility:visible;
}

.mode-tab.selected{
    opacity: 1;
    color:white;
}
.athlete-card{
    width:120px;
    flex-grow:0;
}

.athlete-card.mobile{
    width:100%;
    margin-right:10px;
}

.selector-grid{
    flex-grow:1;
}
</style>
<template>
    <div v-if="!loading" :key="updateKey" style="text-align:left;padding: 0px 10px 0px 10px">
    
    

    <!-- dialogs -->
    <v-dialog v-model="importDialog" width="50%" max-width="600px">
        <v-card>
            <v-card-title>Assessment Criteria Import</v-card-title>
            <v-card-text v-if="feedbackProgress>0">
                <v-progress-circular
                    :size="100"
                    :width="15"
                    :value="feedbackProgress"
                    color="teal"
                    >
                </v-progress-circular>
            </v-card-text>
            <v-card-text v-else>
                You can import your assessment criteria from a specially formatted Excel spreadsheet.

                Please note: this action will remove all your existing criteria as well as any existing assessments for athletes. <b>This cannot be undone.</b>

                <v-file-input v-model="modelFile" label="Skill Criteria data file" @change="readFile" @click:clear="cancel" accept=".xls,.xlsx"/>
                <v-btn :disabled="!readyToProcess"  @click="processData">Process Skill Criteria</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>


    <!-- Main form -->
    
    <ValidationObserver v-slot="{reset}" ref="form">
        <v-row>
            <v-col cols="12" style="text-align:right;display:flex;flex-flow:row">
                <v-btn color="primary" x-small @click="importDialog=true">Import Skills Criteria</v-btn>
                <v-spacer/>
                <v-btn color="primary" x-small @click="dialog=true"> Add new Skill Criterion </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="updateKey !== '---'">
            <v-col cols="11">
            </v-col>
            <v-col cols="1">
                <v-dialog v-model="dialog" persistent max-width="600px">
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">New Criterion</span>
                                </v-card-title>
                                <v-card-text>
                                        <ValidationProvider name="Criterion Name" rules="required" v-slot="{errors}">
                                            <v-text-field
                                                v-model="newName"
                                                prepend-icon="mdi-information"
                                                label="Criterion name"
                                                hide-details="auto"
                                            /> 
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                        <div class="pa-5"></div>
                                        <v-divider/>
                                        <div class="text-left">
                                            <h3>Context</h3>
                                        </div>
                                        <v-checkbox dense v-model="contextIndividual" label="Individual"/>
                                        <v-checkbox dense v-model="contextGroup" label="Group"/>
                                        <v-divider/>
                                        <v-select v-model="newType" label="Criterion Type" :items="types" item-text="name" item-value="value"/>
                                        <v-row v-if="newType==='slider'">
                                            <v-col >
                                                <v-text-field v-model="newLevel5" >
                                                    <template v-slot:prepend>
                                                        <v-icon color="green" large>mdi-numeric-5-circle</v-icon> 
                                                    </template>
                                                </v-text-field>
                                                <v-text-field v-model="newLevel4" prepend-icon="mdi-numeric-4-circle">
                                                    <template v-slot:prepend>
                                                        <v-icon color="lime" large>mdi-numeric-4-circle</v-icon> 
                                                    </template>
                                                </v-text-field>
                                                <v-text-field v-model="newLevel3" prepend-icon="mdi-numeric-3-circle">
                                                    <template v-slot:prepend>
                                                        <v-icon color="yellow" large>mdi-numeric-3-circle</v-icon> 
                                                    </template>
                                                </v-text-field>
                                                <v-text-field v-model="newLevel2" prepend-icon="mdi-numeric-2-circle">
                                                    <template v-slot:prepend>
                                                        <v-icon color="orange" large>mdi-numeric-2-circle</v-icon> 
                                                    </template>
                                                </v-text-field>
                                                <v-text-field v-model="newLevel1" prepend-icon="mdi-numeric-1-circle">
                                                    <template v-slot:prepend>
                                                        <v-icon color="red" large>mdi-numeric-1-circle</v-icon> 
                                                    </template>
                                                </v-text-field>
                                                <v-text-field v-model="newLevel0" prepend-icon="mdi-numeric-0-circle">
                                                    <template v-slot:prepend>
                                                        <v-icon color="gray" large>mdi-numeric-0-circle</v-icon> 
                                                    </template>
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else-if="newType==='counter'">
                                            <v-col>
                                            </v-col>
                                        </v-row>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDialog" >close</v-btn>
                                    <v-btn color="blue darken-1" text @click="createCriterion(reset)" >Save</v-btn>
                                </v-card-actions>
                            </v-card>
                    </v-dialog>
                </v-col>
            </v-row>

            <!--
            <draggable tag="v-expansion-panels" v-model="orderedList" draggable=".criterion-editor-btn" ghostClass="noghost" handle=".handle">
            -->
            <div >
                <draggable v-if="orderedList.length>0" tag="v-expansion-panels" v-model="orderedList" ghostClass="criterion-editor-btn" handle=".handle" :key="updateKey">
                    <criterion-editor class="criterion-editor-btn" v-for="(id,n) in orderedList" :key="n" :criterionId="id"/>
                </draggable>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CriterionEditor from '@/ClubPanes/NewTryoutSettingsPanels/CriterionEditor'
import draggable from 'vuedraggable'
import {rules} from '@/_helpers/rules'
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import * as XLSX from 'xlsx'
import { v4 as uuidv4} from 'uuid'

export default {
    props: ['criterionId'],
    components: {
        CriterionEditor,
        draggable,
        ValidationObserver,
        ValidationProvider,
    },
    computed: {
        ...mapState({
            status: state => state.criterionStore.status,
            criteria: state => state.criterionStore.data,
            criterionList: state => state.criterionStore.list,
            meta: state => state.criterionStore.meta,
            list: state => state.criterionStore.list,
            selectedTryout: state => state.navigatorStore.selectedTryout,

            feedback:   state => state.feedbackStore.data,
            feedbackByCriterionAndScore: state => state.feedbackStore.listByCriterionAndScore,
            feedbackStatus: state => state.feedbackStore.status,
        }),
        loading: function() {
            return this.status.loading
            || this.feedbackStatus.loading
        },
        orderedList: {
            get() {
                if(this.updateKey ==='---') return []
                return this.list
            },
            set(value) {
                this.updateListOrder(value)
                for(var idx in this.list){
                    this.updateCriterion(this.criteria[this.list[idx]])
                }
            }
        },
        changes() {
            return Object.keys(this.changeTracker).length>0 || this.orderChanges
        },
        feedbackProgress(){
            return (this.feedbackProcessed/(this.feedbackCount))*100
        }
    },
    methods: {
        ...mapActions('criterionStore', {
            findAllCriteriaForEvent: 'findAllForEvent',
            addCriterion: 'addCriterion',
            updateListOrder: 'updateListOrder',
            archiveCriterion: 'archiveCriterion',
            updateCriterion: 'updateCriterion',
            deleteCriterion: 'deleteCriterion',
            updateCriterionProp: 'update',
        }),
        ...mapActions('feedbackStore', [
            'loadAllFeedbackForEvent',
            'deleteFeedback',
            'createFeedback'
        ]),
        cancel(){
            this.newParticipants=[]
            this.duplicateParticipants=[]
            this.errors=[]
            this.warnings=[]
        },
        async createCriterion(reset) {
                let self = this;
                let length = self.list.length
                let contextArray=[]

                if(this.contextIndividual) contextArray.push('individual')
                if(this.contextGroup) contextArray.push('group')
                const newCriterion = {
                    description: this.newName,
                    level0: this.newLevel0,
                    level1: this.newLevel1,
                    level2: this.newLevel2,
                    level3: this.newLevel3,
                    level4: this.newLevel4,
                    level5: this.newLevel5,
                    order: length,
                    eventId: this.selectedTryout,
                    context: contextArray,
                    type: this.newType,
                }
                await self.addCriterion(newCriterion)
                this.dialog = false
                this.newName = ''
                this.newLevel0 = 'Not Assessed'
                this.newLevel1 = 'Not Demonstrated'
                this.newLevel2 = 'Weak'
                this.newLevel3 = 'Satisfactory'
                this.newLevel4 = 'Strong'
                this.newLevel5 = 'Outstanding'
                this.updateKey=uuidv4()
                reset()
        },
        async readFile(selectedFile){
            if(!selectedFile){
                return
            }

            this.progress=0;
            this.warnings=[]

            this.errors=[]
            this.rejects=[]
            this.file = selectedFile
            var reader = new FileReader()

            reader.onload = async (e) => {
                const self = this
               self.showProgress=true
               self.showFileInput=false 

               self.workbook = XLSX.read(e.target.result, {defval:""})
               self.wsnames = self.workbook.SheetNames

               // Each worksheet represents a skill, so lets check that the format of the sheets look right

               for(var idx in self.workbook.Sheets){
                console.log(`Importing sheet ${idx}`)
                 var worksheetArray = XLSX.utils.sheet_to_json(self.workbook.Sheets[idx], {header:1, blankrows:false,defval:""})

                    // count the number of feedback messages for each score
                    const scale={
                        5: worksheetArray[1][1], 
                        4: worksheetArray[1][2], 
                        3: worksheetArray[1][3],
                        2: worksheetArray[1][4],
                        1: worksheetArray[1][5],
                    }

                    // Process the context for thie criterion

                    const contextString = worksheetArray[0][3].toLowerCase()
                    console.log(contextString)
                    var contextArray = contextString.split(',')
                    console.log(contextArray)

                    for(var i=0; i<contextArray.length;i++){
                        if (contextArray[i] !== 'individual' && contextArray[i] !== 'group'){
                            console.log(`Splicing out ${contextArray[i]}`)
                            contextArray.splice(i,1)
                        }
                    }
                    if(contextArray.length===0){
                        console.log('Context array seems empty')

                        contextArray=['individual']
                    } 
                    console.log(contextArray)


                    const criterion = {scale: scale, name: worksheetArray[0][1], 5:[], 4:[], 3:[], 2:[], 1:[], context: contextArray}


                    for(var row=3;row<worksheetArray.length;row++){
                        for(var j=1; j<worksheetArray[row].length;j++){
                            if(worksheetArray[row][6-j]!=="") {
                                this.feedbackCount++
                                criterion[j].push([worksheetArray[row][0], worksheetArray[row][6-j]])
                            }
                        }
                    }
                    criterion.found=false
                    self.importedCriteria.push(criterion)

                 /*
                 if(!Object.prototype.hasOwnProperty.call(firstRow, 'Skill')) {
                    this.setWarning.push(`The Worksheet with name ${this.wsnames[idx]} doesn't match the template provided, and will not be imported`)
                    if
                    this.rejects.push(idx)
                 }
                 */
               }
               this.readyToProcess=true
            }
            await reader.readAsArrayBuffer(this.file); 
        },
        removeItemAll(arr, value) {
            var i = 0;
            while (i < arr.length) {
                if (arr[i] === value) {
                arr.splice(i, 1);
                } else {
                ++i;
                }
            }
            return arr;
        },
        async processData(){
            // First delete the skill data 

            // Then create new skills and feedback
            const deleteCriterion=[...this.criterionList]

            for(var i in this.importedCriteria){

                // Find a matching skill element
                const imported = this.importedCriteria[i]
                console.log(`Processing ${this.importedCriteria[i].name}`)
                console.log(imported)

                var criterionId=null


                for(var j in this.criteria){
                    if(this.criteria[j].description.localeCompare(imported.name, undefined, {sensitivity:'accent'})===0){
                        // Don't delete this one!
                        this.importedCriteria[i].found=true
                        criterionId = this.criteria[j].id
                        this.removeItemAll(deleteCriterion, criterionId)

                        // Delete any feedback associated with this criteria
                        for(var k=1;k<6;k++){
                            // Copy the index array because this will change as we delete elements
                            const targetList = this.feedbackByCriterionAndScore[criterionId][k].slice()
                            for(var l=0;l<targetList.length; l++){
                                const targetFeedback = targetList[l]
                                await this.deleteFeedback({feedbackId: targetFeedback})
                            }
                        }
                        // Update the existing criterion with the "new" scale
                        this.updateCriterionProp({field: 'level0', id: criterionId, value: 'Not Assessed' })
                        this.updateCriterionProp({field: 'level1', id: criterionId, value: imported.scale[1] })
                        this.updateCriterionProp({field: 'level2', id: criterionId, value: imported.scale[2] })
                        this.updateCriterionProp({field: 'level3', id: criterionId, value: imported.scale[3] })
                        this.updateCriterionProp({field: 'level4', id: criterionId, value: imported.scale[4] })
                        this.updateCriterionProp({field: 'level5', id: criterionId, value: imported.scale[5] })
                        this.updateCriterionProp({field: 'context', id: criterionId, value: imported.context })

                        this.updateCriterion(this.criteria[criterionId])
                    } 
                }

                // If criterionId is null, create a new criterion.

                if(criterionId===null){
                    let length = this.list.length

                    const newCriterion = {
                        description: imported.name,
                        level0: 'Not Assessed',
                        level1: imported.scale[1],
                        level2: imported.scale[2],
                        level3: imported.scale[3],
                        level4: imported.scale[4],
                        level5: imported.scale[5],
                        order: length,
                        eventId: this.selectedTryout,
                        context: imported.context,
                    }
                    const resp = await this.addCriterion(newCriterion)
                    criterionId = resp.criterion.id
                }


                // Create new feedback
                for (j=1;j<6;j++){
                    for(k=0;k<imported[j].length;k++){
                        if(criterionId){
                            const criterion = {
                                eventId: this.selectedTryout,
                                criterionId: criterionId,
                                associatedScore: j, 
                                category: imported[j][k][0],
                                text: imported[j][k][1],
                            }
                            await this.createFeedback(criterion)
                            this.feedbackProcessed++
                        }
                    }
                }
            }


            for(var idx in deleteCriterion){

                const criterionId = deleteCriterion[idx]
                this.deleteCriterion({criterionId})

                


            }

            this.feedbackProcessed=0
            this.importDialog=false
        },
        closeDialog(){
            this.newName=''
            this.newLevel0= 'Not Assessed'
            this.newLevel1= 'Not Demonstrated'
            this.newLevel2= 'Weak'
            this.newLevel3= 'Satisfactory'
            this.newLevel4= 'Strong'
            this.newLevel5= 'Outstanding'
            this.contextIndividual=true
            this.contextGroup=false

            this.$refs.form.reset()
            this.dialog=false
        },
        saveDetails(){
            // We could save only the changed ones here, but we're being lazy :)
            for(var idx in this.criterionList) {
                const criterionId = this.criterionList[idx]
                // Rebuild the context array based on current data...

                this.updateCriterion(this.criteria[criterionId])
            }
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
            this.$root.$emit("settings-clear-criteria-changes-flags")
        }
    },
    mounted(){
        this.$root.$on('settings-criteria-changes-cleared', (id)=> {
            var self=this
            self.$delete(self.changeTracker, id)
        }),
        this.$root.$on('settings-criteria-changes-made', (id) => {
            var self=this
            self.$set(self.changeTracker, id, true)
        })
        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', async () => {
            if(this.changes){
                await this.findAllCriteriaForEvent(this.selectedTryout)
            }
        })
        this.$root.$on('tryoutRefresh',this.refreshHandler)
    }, 
    async created() {
        await this.findAllCriteriaForEvent(this.selectedTryout)
        await this.loadAllFeedbackForEvent({eventId: this.selectedTryout})
    },
    beforeDestroy(){
        this.$root.$off('tryoutRefresh',this.refreshHandler)
    },
    data() {
        return {
            refreshHandler: async () => {
                await this.findAllCriteriaForEvent(this.selectedTryout)
                await this.loadAllFeedbackForEvent({eventId: this.selectedTryout})
            },
            feedbackCount:0,
            feedbackProcessed: 0,
            updateKey: '',
            readyToProcess: false,
            importedCriteria: [],
            wsnames: null,
            workbook: null,
            worksheetArray: null,

            file: null, 
            modelFile: null,
            showProgress: false,
            showFileInput: false,
            importDialog: false,


            orderChanges: false,
            changeTracker: {},
            rules: rules,
            dialog: false,
            newName: '',
            newLevel0: 'Not Assessed',
            newLevel1: 'Not Demonstrated',
            newLevel2: 'Weak',
            newLevel3: 'Satisfactory',
            newLevel4: 'Strong',
            newLevel5: 'Outstanding',

            newType: 'slider',

            contextIndividual: true,
            contextGroup: false,

            scale: {},
            types: [
                {
                    name: 'Slider',
                    value: 'slider'
                },
                {
                    name: 'Counter',
                    value: 'counter'
                }
            ]

        }
    },
}

</script>

<style>

</style>
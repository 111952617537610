<template>
    <v-card v-if="!loading" width="100%">
        <v-card-title>
            {{ selectedEpisode }}
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <div style="display: flex; flex-flow: row;">
                        <img height="50" :src="clubs[selectedClub].logo"/>
                        <div>
                            <div style="text-align:center">
                                <h3>{{ events[selectedEvent].title }}</h3>
                            </div>
                            <div>
                                {{ seasons[events[selectedEvent].seasonId].name }}
                            </div>
                        </div>

                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-top:auto;margin-bottom:auto">
                    <h3>
                        {{ $capitalize(events[selectedEvent].episodeFormName) }}
                    </h3>
                </v-col>
            </v-row>
            <v-expansion-panels style="margin-top:10px;margin-bottom:10px;">
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <h3>Session Details</h3>
                        <v-spacer/>
                        <div v-if="!episodes[selectedEpisode].published" class="role-pill" style="background-color:teal">Evaluation Incomplete</div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-if="!selectedParticipant">
                            Noone Selected

                        </div>
                        <div v-else>
                            <v-row class="" >
                                <v-col cols="6" sm="6" md="4" lg="4" xl="4" class="">
                                    <v-text-field prepend-icon="mdi-account" readonly dense hide-details="true" v-model="participantName" :label="$capitalize(events[selectedEvent].participantPronoun)"></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="4" xl="4" class="">
                                    <v-text-field prepend-icon="mdi-redhat" readonly dense hide-details="true" v-model="participantRole" label="Role"/>
                                </v-col>
                                <v-col cols="6" sm="6" md="4" lg="4" xl="4" class="">
                                    <v-text-field hide-details="true" dense prepend-icon="mdi-clipboard-list-outline" label="Coach" readonly v-model="coachName"/>
                                </v-col>
                            </v-row>
                            <v-row class=""> 
                                <v-col cols="6" sm="6" md="6" lg="3" xl="3" class="" style="padding-right:10px;">
                                    <v-text-field
                                        v-model="startDate"
                                        dense
                                        hide-details="true"
                                        :label="$capitalize(events[selectedEvent].eventNoun) +' Date'"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                    />
                                </v-col>
                                <v-col colr="6" sm="6" md="6" lg="3" xl="3" class="">
                                    <v-text-field
                                        dense
                                        hide-details="true"
                                        v-model="startTime"
                                        label="Evaluation Time"
                                        prepend-icon="mdi-clock-time-four-outline"
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="4" xl="4">
                                </v-col>
                            </v-row>
                            <v-row>
                                <h3>Match Information</h3>
                            </v-row>
                            <v-row >
                                <v-col v-for="questionId in questionListByContext['devform']" :key="questionId" cols="6" sm="6" md="6" lg="6" xl="6" class="question-area">
                                    <QuestionRender readonly :qid="questionId" :value="qresponses[questionId].response"/>
                                
                                </v-col>
                            </v-row>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


            
            <v-row class="no-boundaries">
            </v-row>
            <v-row class="no-boundaries">
            <v-divider/>
            </v-row>
            <v-row  class="no-boundaries">
                <v-tabs v-model="tab" show-arrows fixed-tabs       background-color="deep-purple accent-4" center-active dark>
                    <v-tab v-for="item in dataPanels" :key="item.key" :href="item.href">
                        <template v-slot:default>
                            <div v-responsive.md.lg.xl >
                            {{ item.text }}
                            </div>
                            <div v-responsive.sm.xs style="font-size:x-small;">
                            {{ item.text }}
                            </div>
                        </template> 
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" style="width:100%">
                    <v-tab-item key="3" value="tab-3">
                        <v-row style="margin-top:10px;">
                            <v-col cols="2" sm="2" md="1" lg="1" xl="1">
                                <em>Rating</em>
                            </v-col>
                            <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="text-left">
                                <em>Skill</em>
                            </v-col>


                        </v-row>
                        <v-row v-for="criterionId in selectedCriterionList" :key="criterionId">
                            <v-col cols="2" sm="2" md="1" lg="1" xl="1">
                                <v-progress-circular :color=colorScore(criterionId) :value="criterionScore(criterionId)*20" rotate="-90">

                                </v-progress-circular>
                            </v-col>
                            <v-col cols="10" sm="10" md="5" lg="5" xl="5" class="text-left">
                                {{ criteria[criterionId].description }}
                            </v-col>

                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="2" value="tab-2">
                        <v-row class="no-boundaries">
                            <v-col v-for="questionId in questionListByContext['episodeform']" :key="questionId" cols="12" sm="12" md="12" lg="12" xl="12" class="no-boundaries question-area">
                                <!-- <QuestionRender :readonly="mode==='coach'?false:true" :qid="questionId" :value="qresponses[questionId].response"/>-->
                                <QuestionRender :readonly="mode==='coach'?false:true" :qid="questionId" :value="getResponse(selectedParticipant, questionId)"/>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="1" value="tab-1">
                        <v-row class="no-boundaries">
                            <v-col v-for="questionId in questionListByContext['participantreflection']" :key="questionId" cols="12" sm="12" md="12" lg="12" xl="12" class="no-boundaries question-area">
                                <!--<QuestionRender :readonly="mode==='coach'?true:false" :qid="questionId" :value="qresponses[questionId].response"/>-->
                                <QuestionRender :readonly="mode==='coach'?true:false" :qid="questionId" :value="getResponse(selectedParticipant, questionId)"/>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="4" value="tab-4">

                    </v-tab-item>
                </v-tabs-items>
            </v-row>
                
        </v-card-text>
        <v-card-actions>
            <v-btn color="secondary" text small @click="close()">Cancel</v-btn>
            <v-spacer/>
            <v-btn color="primary" text small @click="save(selectedEpisode, selectedParticipant, savedResponse, changes)">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import {mapState, mapActions} from 'vuex'
/*
import SkillSlider from '@/components/SkillSlider'
import SkillSliderH from '@/components/SkillSliderH'
*/
import QuestionRender from '@/components/QuestionRender'

export default{
    props: {
        mode: {
            type: String,
            default: 'participant'
        },
        viewer: {
            type: String,
            default: null,
            required: true,
        }
    },
    components:{
    /*
        SkillSlider,
        SkillSliderH,
    */
        QuestionRender,
    },
    computed: {
        ...mapState({
            clubs                       :   state => state.clubStore.data,
            events                      :   state => state.tryoutStore.data,
            questions                   :   state => state.appquestionStore.data,
            questionListByContext       :   state => state.appquestionStore.listByContext,
            qresponses                  :   state => state.qresponseStore.responses,
            qresponseByParticipant: state => state.qresponseStore.byParticipant,
            seasons                     :   state => state.seasonStore.data,
            episodes                    :   state => state.episodeStore.data,
            episodeStatus               :   state => state.episodeStore.status,
            participants                :   state => state.participantStore.data,
            criteria                    :   state => state.criterionStore.data,
            criterionList               :   state => state.criterionStore.list,
            roles                       :   state => state.roleStore.data,
            playerskills                :   state => state.playerskillStore.data,
            playerskillStatus           :   state => state.playerskillStore.status,

            selectedEvent               :   state => state.navigatorStore.selectedTryout,
            selectedClub                :   state => state.navigatorStore.selectedClub,
            selectedEpisode             : state => state.navigatorStore.selectedEpisode,
            selectedParticipant         :   state => state.navigatorStore.selectedParticipant,


            
        }),
        loading(){
            return !this.dataReady ||
                false;
        },
        coachName(){
            return `${this.episodes[this.selectedEpisode].staffList[0].firstName} ${this.episodes[this.selectedEpisode].staffList[0].lastName}`
        },
        startDate(){
            return new Date(this.episodes[this.selectedEpisode].date).toLocaleDateString()
        },
        startTime(){
            return (new Date(this.episodes[this.selectedEpisode].date).toLocaleTimeString())
        },
        participantName(){
            return `${this.participants[this.selectedParticipant].firstName} ${this.participants[this.selectedParticipant].lastName}`
        },
        participantRole(){
            var roleStr = 'No active role'


            const episode = this.episodes[this.selectedEpisode]

            const participantList = episode.participantList

            for(var i=0;i<episode.participantList.length;i++){
                if(participantList[i].participantId === this.selectedParticipant){
                    const roleId = participantList[i].role
                    roleStr = this.roles[roleId].name
                }
            }
            return roleStr
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        },
        landscapeOrientation(){
            return this.orient==='landscape'
        },
        dataPanels(){
            if(this.mode==='participant') return this.menuItems.filter((item)=>{return item.admin==false && (item.published?this.episodes[this.selectedEpisode].published && item.published:true)});
            return this.menuItems
        },
        selectedCriterionList: {
            get(){
                if(this.selectedSession && this.sessions[this.selectedSession] && this.sessions[this.selectedSession].criteria.length>0){
                    if(this.invertSkills){
                        return this.criterionList.filter(x => !this.sessions[this.selectedSession].criteria.includes(x));    
                    } else {
                        return this.sessions[this.selectedSession].criteria
                    }
                }
                return this.criterionList
            }
        },
    },
    methods: {
        ...mapActions('playerskillStore', [
            'loadAllSkillScoresForParticipant'    
        ]),
        ...mapActions('episodeStore', [
            'loadEpisode'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent'
        ]),
        ...mapActions('qresponseStore',[
            'loadAllQResponsesForEvent',
            'addQResponse',
            'updateQResponse',
            'loadAllQResponsesForParticipant'
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent'
        ]),
        ...mapActions('criterionStore', {
            findAllCriterionForEvent : 'findAllForEvent',
        }),
        close(){
//            this.save(this.selectedEpisode, this.selectedParticipant, this.savedResponse, this.changes)
            this.$root.$emit('close-episode-render')
        },
        save(episodeId, participantId, savedResponse, changes ){
            console.log(episodeId)
            // Add any responses that have been saved....
            const keys = Object.keys(savedResponse)
            // Save only those questions that have been changed
            var activity=false

            for(var idx in keys){
                const qid=keys[idx]
                if(changes[qid]){
                    activity=true
                    this.addQResponse({
                        participantId: participantId,
                        eventId: episodeId,
                        questionId: qid,
                        response: savedResponse[qid]
                    })
                }
            }
            this.$root.$emit('close-episode-render')
            if(activity)
                this.$toast.success('Responses saved')


        },
        getResponse(participantId, questionId){
            if(!Object.prototype.hasOwnProperty.call(this.qresponseByParticipant, participantId)) return null
            if(!Object.prototype.hasOwnProperty.call(this.qresponseByParticipant[participantId], questionId)) return null
            return this.qresponseByParticipant[participantId][questionId].response
        },
        criterionAverage(criterionId){
            return this.playerskills[this.selectedParticipant].criteria[criterionId].average/5*100
        },
        criterionAverageScore(criterionId){
            return this.playerskills[this.selectedParticipant].criteria[criterionId].average
        },
        criterionScore(criterionId){
            return Math.round(this.playerskills[this.selectedParticipant].criteria[criterionId].score)
        },
        colorAverage(criterionId){
            return this.skillColor[Math.round(this.playerskills[this.selectedParticipant].criteria[criterionId].average)]
        },
        colorScore(criterionId){
            return this.skillColor[Math.round(this.playerskills[this.selectedParticipant].criteria[criterionId].score)]

        },
        response(questionId){
            if(!Object.prototype.hasOwnProperty.call(this.qresponses, questionId)) return "N/A"

            switch(this.questions[questionId].type){
                case "select":
                    return this.getSelectText(questionId);
                case "checkBox":
                    return this.qresponses[questionId].response?"Yes":"No"
                case "role":
                    return 
            }
        },
        rawResponse(questionId){
            if(Object.prototype.hasOwnProperty.call(this.qresponses, questionId)) return this.qresponses[questionId].response
            return "N/A"
        },
        renderQuestionLabel(questionId){
            const list = this.questions[questionId].question.split('*')
            var bold=false
            var output=''

            for(var i=list[0]===''?1:0;i<list.length;i++){
                if(list[i]==="" ){
                    bold=!bold
                    if(bold) output+='<span>' 
                        else output += '</span>'
                } else {
                    output+=list[i]
                }
            }
            return output
        }
    },
    watch: {
        async selectedEpisode(){
            this.dataReady=false
            await this.loadAllSkillScoresForParticipant({eventId: this.selectedEpisode, participantId: this.selectedParticipant})
            await this.loadAllQResponsesForEvent({eventId: this.selectedEpisode})
            this.dataReady=true
        }
    },
    mounted() {
        this.$root.$on('changed-question-response', (response)=> {
            var self=this
            this.changes[response.qid]=true
            self.savedResponse[response.qid] = response.value
        })
        this.$root.$on('close-episode-render-dialog', ()=>{
//            var self=this
//            self.save(self.selectedEpisode, self.selectedParticipant, self.savedResponse, self.changes)
        })
    },
    unmounted(){
        this.$root.$off('changed-question-response')
        this.$root.$off('close-episode-render-dialog')
    },
    beforeDestroy(){
        this.$root.$off('changed-question-response')
        this.$root.$off('close-episode-render-dialog')
    },
    async created(){
        this.dataReady=false

        await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})
        await this.findAllCriterionForEvent(this.selectedEvent)
        await this.loadAllSkillScoresForParticipant({eventId: this.selectedEpisode, participantId: this.selectedParticipant})
        await this.loadAllQResponsesForParticipant({participantId: this.selectedParticipant, episodeId: this.selectedEpisode})
        this.menuItems=[
                { text: `${this.$capitalize(this.events[this.selectedEvent].participantPronoun)} Reflection`, key: "1", value: 'tab-1', href: "#tab-1", admin: false, published:false},
                { text: "Comments", key: "2", value: 'tab-2', href: "#tab-2", admin: false, published:true},
                { text: "Criteria", key: "3", value: 'tab-3', href: "#tab-3", admin: false, published:true},
            //    { text: "Staff Notes", key: "4", value: 'tab-4', href: "#tab-4", admin: true, published:true},

            ]
        this.dataReady=true
    },
    data(){
        return {
            changes: {},
            savedResponse:{},
            dataReady: false,
            tab: 0,
            menuItems:[],
            skillColor: [
                "#e43f3e",
                "#f0864c",
                "#f8c43c",
                "#c2cc36",
                "#5aaf2b",
                "#5aaf2b",

            ],
            icons: [
                "mdi-numeric-0-circle", 
                "mdi-numeric-1-circle", 
                "mdi-numeric-2-circle", 
                "mdi-numeric-3-circle", 
                "mdi-numeric-4-circle", 
                "mdi-numeric-5-circle"
                ],
        }
    }

}
</script>

<style module>
#htmlContainer > span {
color:blue;
font-weight:600;
}

#htmlContainer > h2 {
    color:green;
    font-weight:600;
}
</style>
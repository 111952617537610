<template>
    <loading v-if='loading'/>
    <div v-else>
        <h3> {{ subjectNoun }} Analysis</h3>
        <div class="tip-info">You can select up to {{ maxSelections }} {{ $pluralize(subjectNoun.toLowerCase())}} from the selector at once</div>
        <LineChart ref="linechart" :chart-data="selectedData" :chart-options="analysisChartOptions()" chart-id="analysis-chart" dataset-id-key="analysischart"/>

    </div>
</template>

<script>
import Loading from '@/components/loading'

import {mapState, mapActions} from "vuex"
import { Line as LineChart} from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement, 
    PointElement,
    CategoryScale,
    LinearScale,
    Filler
} from 'chart.js'

ChartJS.register(Filler, Title, Tooltip, Legend, LineElement, PointElement, BarElement, CategoryScale, LinearScale)
ChartJS.register(ChartDataLabels)

export default{
    props: {
        context: {
            default: 'invidivual'
        },
        visible: {
            default:false,
        },
        v: {
            default:''
        }
    },
    components:{
        Loading,
       LineChart,
    },
    computed: {
        ...mapState({
            events:         state => state.tryoutStore.data,
            selectedEvent:  state => state.navigatorStore.selectedTryout,
            maxSelections:  state => state.navigatorStore.maxSelections,
            selectionList:  state => state.navigatorStore.selectionList,

            criteria:       state => state.criterionStore.data,
            criteriaList:   state => state.criterionStore.list,

            groups:         state => state.groupStore.data,
            participants:   state => state.participantStore.data,

            playerskill:    state => state.playerskillStore.data,
        }),
        subjectNoun(){
            return this.context==='individual' ? this.$capitalize(this.events[this.selectedEvent].participantPronoun):this.$capitalize(this.events[this.selectedEvent].groupPronoun) 
        },
        loading(){
            return false ||
                false;
        },
        selectedData(){
            const chartData={}
            chartData.labels=[]
            const groupCriteria=[]
            const maxCounters={}
            const ids=[]
            // For each criterion that is a counter we need to figure out it's scaling factor...

            for(var criterionId of this.criteriaList){
                if (this.criteria[criterionId].context.includes(this.context)){
                    ids.push(criterionId)
                    groupCriteria.push(criterionId)
                    chartData.labels.push(this.criteria[criterionId].description)
                    var max=0
                    /*
                    console.log(`CriterionId:${criterionId}`)
                    console.log(this.criteria[criterionId])
                    console.log(this.criteria[criterionId].type)
                    */
                    if(this.criteria[criterionId].type==='counter'){
                        for(var groupId of this.selectionList){
                            const avg = this.average(groupId, criterionId)
                            max= avg>max? avg:max
                        }
                        maxCounters[criterionId]=max
                    }
                }
            }
            /*
            console.log('Max counters')
            console.log(maxCounters)
            */

            chartData.datasets=[]
            for(var id of this.selectionList){
                const chartNode={}
                console.log(`PartId: ${id}`)
                chartNode.label=this.context==='group'?this.groups[id].name :
                                `${this.participants[id].firstName} ${this.participants[id].lastName}`

                chartNode.data=[]
                chartNode.maxCounters=maxCounters
                chartNode.ids=ids
                for(var criterionId2 of groupCriteria){
                    if(this.criteria[criterionId2].type==='counter'){
                        chartNode.data.push(this.average(id, criterionId2)/maxCounters[criterionId2]*5)
                    } else {
                        chartNode.data.push(this.average(id, criterionId2))
                    }
                }
                chartNode.fill=true
                chartNode.borderColor=this.colors[this.selectionList.indexOf(id)].borderColor
                chartNode.backgroundColor=this.colors[this.selectionList.indexOf(id)].backgroundColor
                chartNode.tension=0.4
                chartData.datasets.push(chartNode)
            }

            return chartData

        },
    },

    methods: {
        ...mapActions('navigatorStore', [
            'setAllowMultipleSelection',
            'setMaxSelections',
        ]),
        visibleHandler(){
        },
        average(participantId, criterionId) {
            if(participantId && participantId !== 0 && criterionId !==0 && this.playerskill[participantId].criteria[criterionId].average !== null) {
                return this.playerskill[participantId].criteria[criterionId].average.toFixed(1)
            } else {
                return 0
            }
        },
        analysisChartOptions(){
            return {
                    /*
                events: ['click'],
                
                onClick: async (e) => {

                    const clickData = e.chart.getElementsAtEventForMode(
                        e,
                        'nearest',
                        { intersect: true },
                        false
                    );
                    if(clickData.length>0 ){
                        console.log(`setting index to ${this.timeSeries[clickData[0].index].episodeId}`)
                        this.dataReady=false
                        
                        this.loadEpisode({episodeId: this.timeSeries[clickData[0].index].episodeId})
                        this.setSelectedEpisode(this.timeSeries[clickData[0].index].episodeId)

                        this.dataReady=true
                        this.renderEpisode = true

                    }
                },
                        */
                
                plugins: {
                    title: {
                        display: false,
                        text: 'Team Comparative Analysis'
                    },
                    legend: {
                        display: true
                    },
                    datalabels: {
                        color: 'black',
                        display: false,
                        /*
                        display: function(context){
                            const index =  context.dataIndex
                            return context.dataset.data[index]>0
                        }
                        */
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                let label = context.dataset.label || '';
                                const value = context.parsed.y

                                if(Object.prototype.hasOwnProperty.call(context.dataset.maxCounters, context.dataset.ids[context.dataIndex])){

                                    // This was a counter, so lets scale the value for the tooltip
                                    const criterionId = context.dataset.ids[context.dataIndex]
                                    label += ': ' + context.dataset.data[context.dataIndex]/5*context.dataset.maxCounters[criterionId]
                                } else {
                                    label += ': ' + value
                                }
                                return label;
                            }
                        }
                    }
                },
                scales: {
                    y: {
                        min: 0,
                        max:5,
                        labels:{
                            font: {
                                size:0
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 8
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        }


    },
    watch: {
        visible(){
            if(this.visible) {
                this.setMaxSelections(5)
                this.setAllowMultipleSelection(true)
            } else {
                this.setAllowMultipleSelection(false)
            }
        }
    },
    mounted(){

        if(this.visible){
            this.setMaxSelections(5)
            this.setAllowMultipleSelection(true)
        }

    },
    async created(){

    },
    data(){
        return {
            colors: [
                {
                    borderColor: 'rgb(0,170,0)',
                    backgroundColor: 'rgba(0,170,0,0.1)'
                },
                {
                    borderColor: 'rgb(170,0,0)',
                    backgroundColor: 'rgba(170,0,0,0.1)'
                },
                {
                    borderColor: 'rgb(170,170,0)',
                    backgroundColor: 'rgba(170,170,0,0.1)'
                },
                {
                    borderColor: 'rgb(0,170,170)',
                    backgroundColor: 'rgba(0,170,170,0.1)'
                },
                {
                    borderColor: 'rgb(170,0,170)',
                    backgroundColor: 'rgba(170,0,170,0.1)'
                },
            ]

        }
    }

}
</script>
<style scoped>

</style>
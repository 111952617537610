<template>
    <loading v-if="loading" :progress="progress" :progressMessage="progressMessage"/>
    <div v-else-if="tryouts.length === 0 || tryouts[selectedTryout] && !tryouts[selectedTryout].active">
        <div class="no-parts">
        This tryout is not currently active. Please go to <b><i>Settings -> Publication Details</i></b> and select <b><i>"Activate This Tryout"</i></b> to start collecting data.

        </div>
    </div>
    <div v-else-if="participantList.length===0">
        <div class="no-parts">
            There are currently no trialists registered in this trial
        </div>

    </div>
    <div v-else style="/*height:100vh;*/width:100%;padding-left:5px" :key="updateKey">
        <v-row class="no-boundaries " v-if="selectedSession" style="margin-bottom:5px;">
                <!-- 
                <div style="padding-left:10px">
                    <b>Selected Session:</b> {{this.sessions[this.selectedSession].title}}
                </div>
            -->
                <v-slide-group show-arrows v-model="liveActivity" >
                    <v-slide-item
                        v-for="(activity,i)  in sessionActivities"
                        :key="activity.id"
                        v-slot="{ active, toggle }"
                    >
                            <v-btn
                                class="mx-2"
                                :input-value="active"
                                active-class="active-button purple white--text"
                                depressed
                                rounded
                                @click="toggle"
                            >
                                <v-dialog width="100%">
                                    <template v-slot:activator="{on,attrs}">
                                        <v-btn icon  :color="active?'white':'primary'" v-bind="attrs" v-on="on" depressed>
                                            <v-icon :style="infoIconStyle(activity)">mdi-information</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <RenderActivityDescription :activityId="activity"/>
                                    </v-card>
                                </v-dialog>
                                {{ i+1 }}: {{ activities[activity].name }} ({{ activities[activity].duration}} mins )
                            </v-btn>
                    </v-slide-item>
                </v-slide-group>
        </v-row>
        <v-row class="no-boundaries">
            <SelectorPanel :selectedActivity="selectedActivity" :showGroupSelector="showGroupSelector"/>
        </v-row>

        <v-row style="margin:0px;padding:0px;">
            <v-tabs
                v-model="tab"
                show-arrows
                fixed-tabs
            >
                <!-- 
                <v-tab v-for="item in dataPanels" v-if="item.admin? isOwner : true" :key="item.key" :href="item.href">
                -->
                <v-tab v-for="item in dataPanels" :key="item.key" :href="item.href">
                    <template v-slot:default>
                        <div v-responsive.md.lg.xl >
                        {{ item.text }}
                        </div>
                        <div v-responsive.sm.xs style="font-size:x-small;">
                        {{ item.text }}
                        </div>

                    </template>
                </v-tab>
                
            </v-tabs>
            <!-- 
            <v-select class="tab-select" v-responsive.sm.xs :items="menuItems" item-text="text" item-value="value" v-model="tab"></v-select>
            -->
        </v-row>
        <v-row :class="orient==='landscape'?'tab-row-l':'tab-row'">

            <v-tabs-items v-model="tab" style="width:100%;flex: 1 1 auto;">
                <v-tab-item key="1" value="tab-1">
                    <v-switch style="margin-left:15px;" label="Show hidden Skill Criteria"  v-model="invertSkills" hide-details dense></v-switch>
                    <div v-if="miniScreen">
                        <div v-if="orient==='landscape'" class="sm-criteria-container">
                            <v-row v-if="orient==='landscape'">
                                <v-col v-for="criterionId in selectedCriterionList" :key="criterionId" >
                                        <skill-slider v-if="criteria[criterionId].type === 'slider'" :criterionId="criterionId" :participantId ="selectedPlayer"/>
                                        <skill-counter v-if="criteria[criterionId].type==='counter'" :criterionId="criterionId" :participantId="selectedPlayer"/>
                                </v-col> 
                            </v-row>
                        </div>
                        <div v-else class="sm-criteria-container">
                            <div v-for="criterionId in selectedCriterionList" :key="criterionId">
                                <skill-slider-h v-if="criteria[criterionId].type==='slider'" :criterionId="criterionId" :participantId="selectedPlayer"/>
                                <skill-counter horizontal v-if="criteria[criterionId].type==='counter'" :criterionId="criterionId" :participantId="selectedPlayer"/>
                            </div>
                        </div>
                    </div>
                    <div v-else class="skill-container">
                        <v-row>
                            <div v-if="selectedPlayer==='nogroup'" class="no-group-message">
                                Stats will be visible after a group has been selected/created.
                            </div>
                            <v-col v-else v-for="criterionId in selectedCriterionList" :key="criterionId" >
                                    <skill-slider v-if="criteria[criterionId].type==='slider'" :criterionId="criterionId" :participantId ="selectedPlayer" :key="updateKey"/>
                                    <skill-counter v-if="criteria[criterionId].type==='counter'" :criterionId="criterionId" :participantId="selectedPlayer"/>
                            </v-col> 
                        </v-row> 

                    </div>
                </v-tab-item>
                <v-tab-item key="2" value="tab-2">

                        <div v-responsive.xs.sm v-for="attributeId in attributeList" :key="'s-'+attributeId" class="sm-criteria-container">
                                    <attribute-box :attributeId="attributeId" :participantId="selectedPlayer" style="width:100%"/>
                        </div>
                        <div v-responsive.md.lg.xl v-for="attributeId in attributeList" :key="attributeId">
                            <attribute-box :attributeId="attributeId" :participantId="selectedPlayer"/>
                        </div>

                </v-tab-item>
                <v-tab-item key="3" value="tab-3">
                    <div style="width:100%">
                        <v-text-field 
                            label= "Identifying Remark"
                            v-model="staticIdRemark"
                            v-on:blur="idRemarkInput"
                            class="pa-6"
                        />
                        <v-textarea 
                            v-model="staticComment"
                            outlined
                            :label="commentLabelString"
                            class="pa-6"
                            v-on:blur="commentInput"
                        />
                    </div>
                </v-tab-item>
                <v-tab-item key="4" value="tab-4">
                    <analysis-tab :visible="tab==='tab-4'" :v="tab" :participantId="selectedPlayer" :staffId="staffId" :context="participantContext"/>
                </v-tab-item>
                <v-tab-item v-if="isOwner" key="5" value="tab-5">
                    <recommendation-tab :participantId="selectedPlayer" :staffId="staffId"></recommendation-tab>
                </v-tab-item>
                <v-tab-item key="6" value="tab-6">
                    <ReviewCard :participantId="selectedPlayer" small="true"/>
                </v-tab-item>
            </v-tabs-items>

        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Loading from '@/components/loading'
import SkillSlider from '@/components/SkillSlider'
import SkillSliderH from '@/components/SkillSliderH'

import SkillCounter from '../../components/SkillCounter.vue';

import AttributeBox from '@/components/AttributeBox'
import RecommendationTab from '@/ClubPanes/capturePanels/RecommendationTab'
import AnalysisTab from '../capturePanels/AnalysisTab.vue';
import Color from 'colorjs.io/dist/color.legacy.cjs'

import SelectorPanel from '@/components/SelectorPanel'

import RenderActivityDescription from '../../components/RenderActivityDescription.vue';

import ReviewCard from '@/components/ReviewCard.vue';
import { v4 as uuidv4} from 'uuid'

export default {
    props: [],
    components: {
        RenderActivityDescription,
        Loading,
        SkillSlider,
        SkillSliderH,
        SkillCounter,
        AttributeBox,
        RecommendationTab,

        SelectorPanel,
        ReviewCard,
        AnalysisTab,


    },
    computed: {
        commentLabelString(){
            if(!this.selectedPlayer || this.selectedPlayer===null || this.selectedPlayer === 'null') return ""
            if(!this.participants[this.selectedPlayer]) return ""
            if(this.staticComment )
                return `Coaches Comments (${this.participants[this.selectedPlayer].comments-1} other coaches have commented) `
            return `Coaches Comments (${this.participants[this.selectedPlayer].comments} other coaches have commented) `
        },
        ...mapState({
            tryouts: state => state.tryoutStore.data,
            tryoutList: state => state.tryoutStore.list,
            tryoutStatus: state => state.tryoutStore.status,

            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.list,
            participantsByLastName: state=> state.participantStore.listByLastName,
            participantsByFirstName: state=> state.participantStore.listByFirstName,
            participantStatus:      state => state.participantStore.status,
            displayList:            state => state.participantStore.displayList,

            playerskills: state => state.playerskillStore.data,
            playerskillsStatus: state => state.playerskillStore.status,

            criteria: state => state.criterionStore.data,
            criterionList : state => state.criterionStore.list,
            criterionStatus: state => state.criterionStore.status,

            attributes: state => state.attributeStore.data,
            attributeList: state => state.attributeStore.list,
            attributeStatus: state => state.attributeStore.status,

            comments: state => state.commentStore.data,
            commentList: state => state.commentStore.list,
            commentStatus: state => state.commentStore.status,

            playerattributes: state => state.playerattributeStore.data,
            playerattributeStatus: state => state.playerattributeStore.status,

            staff: state => state.staffStore.data,
            staffList: state => state.staffStore.list,
            staffStatus: state => state.staffStore.status,

            teamrecommendations: state => state.teamrecommendationStore.data,
            teamrecommendationList: state => state.teamrecommendationStore.list,
            teamrecommendationStatus: state => state.teamrecommendationStore.status,
            roleRecommendations: state => state.teamrecommendationStore.recommendationsByRole,

            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedParticipant: state => state.navigatorStore.selectedParticipant,
            showNames: state => state.navigatorStore.showNames,

            sessions: state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,

            attendancePerSession: state => state.attendanceStore.perSessionLists,
            attendance: state => state.attendanceStore.data,
            attendanceStatus: state => state.attendanceStore.status,

            teams: state => state.teamStore.data,
            teamList: state => state.teamStore.list,

            roles: state => state.roleStore.data,
            roleList: state => state.roleStore.list,
            roleStatus: state => state.roleStore.status,

            activities: state => state.activityStore.data,
            activityList: state => state.activityStore.list,
            perSessionActivities: state => state.activityStore.perSessionLists,

            groups: state => state.groupStore.data,
            groupList: state => state.groupStore.list,
            groupsByActivity: state => state.groupStore.groupsByActivity,

            appQuestions: state => state.appquestionStore.data,
            appQuestionStatus: state => state.appquestionStore.status,

            qresponses : state => state.qresponseStore.data,
            qresponseList: state => state.qresponseStore.list,
            qresponseStatus: state => state.qresponseStore.status,

            selectedSession: state => state.navigatorStore.selectedSession,
            networkStatus:      state => state.navigatorStore.networkStatus,
            account: state => state.account,
        }),  
        sessionActivities: function(){
            return this.perSessionActivities[this.selectedSession]
        },
        sessionNameList: function() {
            const results=[{id: 0, name: 'All Sessions'}]
            for(var i=0;i<this.sessionList.length;i++){
                results.push({
                    id: this.sessionList[i],
                    name: this.sessions[this.sessionList[i]].title
                })
            }
            return results
        },
        participantContext(){
            if(this.participantList.includes(this.selectedParticipant)){
                return 'individual'
            } else {
                return 'group'
            }
        },
        teamNameList: function() {
            const results=[]
            for(var i=0;i<this.teamList.length;i++){
                if(this.teams[this.teamList[i]].selected){
                    results.push({
                        id: this.teamList[i],
                        name: this.teams[this.teamList[i]].name
                    })
                }
            }
            return results
        },

        roleNameList: function() {
            const results=[]
            for(var i=0;i<this.roleList.length;i++){
                results.push({
                    id: this.roleList[i],
                    name: this.roles[this.roleList[i]].name
                })
            }
            return results
        },

        filterColor: function() {
            if( this.showTeamUnassigned && this.allTeamsSelected && this.allRolesSelected && this.selectedSession === 0  && this.showBypassed) return ''
            return 'red'
        },
        landscapeOrientation(){
            return this.orient==='landscape'
        },
        
        loading: function() {
            return this.tryoutStatus.loading
                || this.participantStatus.loading
                || this.playerskillsStatus.loading
                || this.criterionStatus.loading
                || this.attributeStatus.loading
                || this.commentStatus.loading
                || this.playerattributeStatus.loading
                || this.commentStatus.loading
                || this.staffStatus.loading
                || this.teamrecommendationStatus.loading
                || this.roleStatus.loading
                || this.dataLoading
                || !this.selectedParticipant
                || !this.selectedTryout
        },

        indicatorStyle(){
            return "position:absolute, transform: translate(-12px, 0px);"
        },
        relevantCriterion(){
            return this.criterionList.filter(x => this.criteria[x].context.includes(this.participantContext))
        },
        selectedCriterionList: {
            get(){
                if(this.selectedSession && this.sessions[this.selectedSession] && this.sessions[this.selectedSession].criteria.length>0){
                    if(this.invertSkills){
                        return this.relevantCriterion.filter(x => !this.sessions[this.selectedSession].criteria.includes(x));    
                    } else {
                        return this.relevantCriterion.filter(x => this.sessions[this.selectedSession].criteria.includes(x));
                    }
                }
                return this.relevantCriterion
            }
        },
        selectedPlayer: {
            get(){
                return this.selectedParticipant
            },
            set(value){
                this.setSelectedParticipant(value)
            }   
        },
        showGroupSelector(){
            if(Object.prototype.hasOwnProperty.call(this.groupsByActivity, this.selectedActivity) && this.selectedActivity !== 0){

                return this.groupsByActivity[this.selectedActivity].length>0
            }
            return false
        },
        allTeamsSelected(){
            return this.selectedTeams.length === this.teamNameList.length
        },
        allRolesSelected(){
            return this.selectedRoles.length === this.roleNameList.length
        },
        miniScreen(){
            if (this.$vuetify.breakpoint.smAndDown) {
                return true;
            }
            return false;
        },
        dataPanels(){
            const localUser = JSON.parse(localStorage.getItem('user'))
            const menus = this.menuItems.filter(e => e.context.includes(this.participantContext))
            if(this.tryouts[this.selectedTryout] && this.tryouts[this.selectedTryout].owner === localUser.details.id) return menus
            return menus.filter((item)=>{return item || item.admin==false});
        }
    },
    watch: {
        liveActivity(){
            this.selectedActivity = this.perSessionActivities[this.selectedSession][this.liveActivity]
        },
        selectedSession(){
            this.selectedActivity=0
            if(Object.prototype.hasOwnProperty.call(this.perSessionActivities, this.selectedSession))
                this.selectedActivity = this.perSessionActivities[this.selectedSession][0].id
        },
        selectedParticipant(){
            if(this.selectedParticipant!==null && this.comments[this.selectedParticipant]){
                this.staticComment = this.comments[this.selectedParticipant].comment
                this.idRemark = this.comments[this.selectedParticipant].idRemark

            } else {
                this.staticComment = ""
                this.idRemark = ""

            }

        }
    },
    sockets: {
        connect(){
            // Rejoin the room if we have been disconnected
            this.$socket.emit('join-room', `capture-${this.selectedTryout}`, localStorage.getItem('jwt'))
            this.$socket.emit('join-room', `attendance-${this.selectedTryout}`, localStorage.getItem('jwt'))
            this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
            //this.refresh()
        },
        disconnect(){
        },
        async 'UpdateComment'(){
//Comments are not SharedArrayBuffer, so ignore this
//            await this.updateLocalComment(data)
 //           this.updateKey=uuidv4()
        },
        async 'UpdateAttributes'(data){
            await this.updateLocalAttributeValue(data)
        },
        async 'UpdatePlayerStats'(data){
            await this.updateLocalScore(data)
        },
        async 'UpdatePlayerAttendance'(data) {
            // change our local view of attendance data
            await this.updateLocalAttendance(data)
        },
        async 'NewParticipant'(){
           this.loadData()
        },
        async 'ParticipantUpdated'(data){
            this.updateLocalParticipant(data)
            this.updateKey=uuidv4()

        }

    },
    methods: {
        ...mapActions('tryoutStore', [
            'loadTryoutForEvent',
            'loadCollectDataBundle'
        ]),
        ...mapActions('navigatorStore', {
            selectNav: 'selectNav',
            setSelectedParticipant: 'setSelectedParticipant',
            setNetworkStatus: 'setNetworkStatus'
        }),
        ...mapActions('participantStore', {
            findAllParticipantsForEvent: 'findAllForEvent',
            quietFindAllParticipantsForEvent: 'quietFindAllForEvent',
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
            updateLocalParticipant: 'updateLocal',
        }),
        ...mapActions('playerskillStore', {
            findAllPlayerSkillScores: 'findAllForEventAndStaff',
            quietFindAllPlayerSkillScores: 'quietFindAllForEventAndStaff',
            updateScore: 'updateScore',
            updateLocalScore: 'updateLocalScore'
        }),
        ...mapActions('criterionStore', {
            findAllCriterionForEvent : 'findAllForEvent',
            quietFindAllCriterionForEvent : 'quietFindAllForEvent',
        }),
        ...mapActions('commentStore', {
            findAllComments: 'findAllForEventAndStaff',
            quietFindAllComments: 'quietFindAllForEventAndStaff',
            updateComment: 'update',
            createComment: 'create',

        }),
        ...mapActions('attributeStore', {
            findAllAttributes: 'findAllForEvent',
            quietFindAllAttributes: 'quietFindAllForEvent',
        }),
        ...mapActions('playerattributeStore', {
            findAllAttributeValues: 'findAllForEvent',
            quietFindAllAttributeValues: 'quietFindAllForEvent',
            updateLocalAttributeValue: 'updateLocalAttributeValue',
        }),
        ...mapActions('teamStore', {
            findAllTeams: 'findAllForEvent',
            quietFindAllTeams: 'quietFindAllForEvent'
        }),
        ...mapActions('staffStore',{
            findAllStaff: 'findAllForEvent',
            quietFindAllStaff: 'quietFindAllForEvent',
        }),
        ...mapActions('teamrecommendationStore', {
            loadRecommendations: 'findAllForStaff',
            quietLoadRecommendations: 'quietFindAllForStaff',
        }),
        ...mapActions('sessionStore',{
            findAllSessionsForEvent: 'findAllForEvent',
            quietFindAllSessionsForEvent: 'quietFindAllForEvent'
        }),
        ...mapActions('attendanceStore', {
            findAllAttendanceForEvent: 'findAllForEvent',
            quietFindAllAttendanceForEvent: 'quietFindAllForEvent',
            updateLocalAttendance: 'localUpdate',
        }),
        ...mapActions('roleStore', {
            findRolesForEvent: 'findAllForEvent',
            quietFindRolesForEvent: 'quietFindAllForEvent'
        }),
        ...mapActions('activityStore', {
            findAllActivitiesForEvent: 'findAllForEvent',
            updateActivity: 'update',
        }),
        ...mapActions('groupStore',{
            findAllGroupsForEvent: 'findAllForEvent',
            resetAvailablePlayers: 'resetAvailablePlayers',
        }),
        ...mapActions('qresponseStore', {
            findAllResponsesForEvent: 'findAllForEvent',
        }),
        ...mapActions('appquestionStore', {
            findAllQuestionsForEvent: 'loadAllQuestionsForEvent'
        }),
        ...mapActions('feedbackStore', [
            'loadAllFeedbackForEvent',
        ]),
        ...mapActions('feedbackLinkStore', [
            'loadAllFeedbackLinksForEvent',
        ]),
        sendMessage(){
            this.$socket.emit('chat message', "Hello")
        },

        infoIconStyle(activityId){
            var style="hidden"
            if(this.activities[activityId].description)
                style="visible" 
            
            return "visibility:"+style
        },
        closeFilterDialog(){
            this.filterDialog=false
        },
        async bypass(){
           this.updateParticipantProp({field: 'bypassCollection', id: this.selectedPlayer, value: true}) 
           await this.updateParticipant(this.participants[this.selectedPlayer])
//           this.selectedPlayer = this.displayList[0]
        },
        async unbypass(){
           this.updateParticipantProp({field: 'bypassCollection', id: this.selectedPlayer, value: false}) 
           await this.updateParticipant(this.participants[this.selectedPlayer])
 //          this.selectedPlayer = this.displayList[0]

        },
        refreshList(){
//           this.selectedPlayer = this.displayList[0]
        },
        commentInput: function(value){
            if(this.comments[this.selectedPlayer]){
                this.updateComment({
                    commentId: this.comments[this.selectedPlayer].id,
                    comment: value.target._value,
                    idRemark: this.staticIdRemark
                })
            } else {
                const localUser = JSON.parse(localStorage.getItem('user'))

                this.createComment({
                    eventId: this.selectedTryout,
                    participantId: this.selectedPlayer,
                    userId: localUser.details.id,
                    comment: value.target._value,
                    idRemark: this.staticIdRemark,
                })

            }
            this.staticComment = value.target._value
        },

        idRemarkInput: function(value){
            if(this.comments[this.selectedPlayer]){
                this.updateComment({
                    commentId: this.comments[this.selectedPlayer].id,
                    idRemark: value.target._value,
                    comment: this.staticComment
                })
            } else {
                const localUser = JSON.parse(localStorage.getItem('user'))

                this.createComment({
                    eventId: this.selectedTryout,
                    participantId: this.selectedPlayer,
                    userId: localUser.details.id,
                    comment: this.staticComment,
                    idRemark: value.target._value
                })
            }
            this.staticIdRemark = value.target._value
        },


        participantNumber: function (id) {
            if(!id || id===0) return '-'
            return this.participants[id].participantNumber
        },
        participantFirstName: function (id) {
            if(!id || id===0) return '-'
            return this.participants[id].firstName
        },
        participantLastName: function (id) {
            if(!id || id===0) return '-'
            return this.participants[id].lastName
        },
        firstName: function(playerId){
            if(playerId && playerId !== 0) {
                return this.participants[playerId].firstName
            } else {
                return ""
            }
        },
        junior: function(playerId){
            if(playerId && playerId !==0){
                return this.participants[playerId].junior
            } else {
                return false
            }
        },
        
        profileImage: function(playerId) {
            if(playerId && playerId !== 0) {
                return this.participants[playerId].profileImage
            } else {
                return ""
            }
        },
        lastName: function(playerId){
            if(playerId && playerId !== 0) {
                return this.participants[playerId].lastName
            } else {
                return ""
            }
        },
        isOwner : function() {
            const localUser = JSON.parse(localStorage.getItem('user'))
            if(this.tryouts[this.sessionId].owner === localUser.details.id) return true
            return false
        },
        isStaff : function() {
            const localUser = JSON.parse(localStorage.getItem('user'))
            for(var idx in this.staff){
                if(this.staff[idx].userId === localUser.details.id) return true
            }
            return false

        },
        playerColor: function(playerId){
            if(playerId && playerId !== 0){
                let complete = this.playerskills[playerId].complete
                
                let color= new Color('p3',[1,0,0])
                let redGreen = color.range("lime", {
                    space: "lch",
                    outputSpace: "srgb",
                    
                })
                const result =  redGreen(complete/100)
                return result.toString({format:"hex"})

            }
        },
        handleOrientationChange() {
            const orientation = window.screen.orientation.type
            if (orientation === "portrait-primary") {
                // portrait mode
                this.orient="portrait"
            } else if (orientation === "landscape-primary") {
                // landscape mode
                this.orient="landscape"
            }
        },

        toggleAllTeams() {
            this.$nextTick(()=> {
                if(this.allTeamsSelected) {
                    this.selectedTeams=[]
                    this.showTeamUnassigned=0
                } else {
                    this.showTeamUnassigned=1
                    this.selectedTeams = this.teamNameList.map(a => a.id)
                }
            })
        },
        toggleAllRoles() {
            this.$nextTick(()=> {
                if(this.allRolesSelected) {
                    this.selectedRoles=[]
                    this.showRoleUnassigned=0
                } else {
                    this.showRoleUnassigned=1
                    this.selectedRoles = this.roleNameList.map(a => a.id)
                }
            })
        },
        async loadData() {
            this.dataLoading=true
        //    const startTime = window.performance.now()


            const userId = this.account.user.details.id

            if(! this.tryouts[this.selectedTryout]) {
                await this.loadTryoutForEvent({eventId: this.selectedTryout})
            }

            this.progress=5
            this.progressMessage="Rounding up all the participants"

            await this.findAllParticipantsForEvent(this.selectedTryout)

            await this.findAllGroupsForEvent({eventId:this.selectedTryout}) 
            await this.findAllActivitiesForEvent({eventId: this.selectedTryout})
            this.progress=10
            this.progressMessage="Fetching all their application data"
            // Is the selected player in one of our lists? If not make it the first player
            if(!(this.selectedPlayer && (this.participantList.includes(this.selectedPlayer) || this.groupList.includes(this.selectedPlayer)))){
                this.selectedPlayer = this.participantList.filter(id => !(this.participants[id].archived || (this.showBypassed ? false:this.participants[id].bypassCollection)))[0]
            }

            await this.findAllResponsesForEvent(this.selectedTryout)
            await this.findAllQuestionsForEvent({eventId: this.selectedTryout})

            this.progress=20
            this.progressMessage="Calculating all their scores and averages"

            await this.findAllPlayerSkillScores({eventId: this.selectedTryout, userId})

            this.progress=30
            this.progressMessage="Finding all the comments from staff"

            await this.findAllComments({eventId: this.selectedTryout, userId})
            if(this.comments!= {}){
                this.staticComment = this.comments[this.selectedPlayer] ? this.comments[this.selectedPlayer].comment:""
                this.staticIdRemark = this.comments[this.selectedPlayer]? this.comments[this.selectedPlayer].idRemark:""
            }

            await this.findAllCriterionForEvent(this.selectedTryout)

            this.progress=40
            this.progressMessage="Getting all the evaluation criteria"

            await this.findAllAttributeValues({eventId: this.selectedTryout})
            await this.findAllAttributes({eventId: this.selectedTryout})
            await this.findAllStaff({eventId: this.selectedTryout})

            this.progress=50
            this.progressMessage="Rounding up all your staff"

            const localUser = JSON.parse(localStorage.getItem('user'))
            await this.loadRecommendations({eventId: this.selectedTryout, staffId: localUser.details.id})
                    

            await this.findAllTeams({eventId: this.selectedTryout})
            this.selectedTeams = this.teamNameList.map(a => a.id)

            await this.findRolesForEvent({eventId: this.selectedTryout})
            this.selectedRoles = this.roleNameList.map(a=> a.id)
            this.resetAvailablePlayers({participantList: this.participantList, roleList: this.roleList, activityList: this.activityList})

            await this.findAllSessionsForEvent(this.selectedTryout)

            this.progress=60
            this.progressMessage="Setting up the sessions for this trial"

            await this.findAllAttendanceForEvent({eventId: this.selectedTryout})

            this.progress=80
            this.progressMessage="Loading up all the feedback from staff"

            await this.loadAllFeedbackForEvent({eventId: this.selectedTryout})
            await this.loadAllFeedbackLinksForEvent({eventId: this.selectedTryout})

            this.dataLoading=false;
        },

    },
    mounted() { 
        const localUser = JSON.parse(localStorage.getItem('user'))
        this.staffId = localUser.details.id
        window.addEventListener(
            "orientationchange",
            this.handleOrientationChange
        );
        this.$root.$on('tryoutRefresh', this.refreshHandler)

        this.$socket.emit('join-room', `capture-${this.selectedTryout}`, localStorage.getItem('jwt'))
        this.$socket.emit('join-room', `attendance-${this.selectedTryout}`, localStorage.getItem('jwt'))
        this.$socket.emit('join-room', `data-participant-${this.selectedTryout}`, localStorage.getItem('jwt'))
    },
    beforeDestroy() {
        this.$root.$off('tryoutRefresh',this.refreshHandler)
        this.$socket.emit('leave-room', `capture-${this.selectedTryout}`)
    },
    async created() {
        await this.loadData()


    },
    data() {
        return {
            //
            // Hook identifiers...
            //
            progress: 0,
            progressMessage: 'Getting the event data',

            refreshHandler: ()=>{ 
                var self=this
                self.loadData()
            },
            viewOptions: null,
            updateKey:null,
            msgs:[],
            invertSkills: false,
            liveActivity: null,
            selectedActivity:null,
            filterDialog: false,
            roleSumList: [],
            selectedRoles: [],
            selectedTeams:[],
            showRoleUnassigned: 1,
            showTeamUnassigned: 1,
            showBypassed: true,
            orient: "portrait",
            buttonType: "numbers",
            sortByFirstName: false,
            Color: Color,
            dataLoading: true,
            staffId: null,
            staticComment: '',
            staticIdRemark: '',
            tab: 0,
            mode: "0",
            polling: null,
            clearPolling: null,
            timeoutPoll:null,
//--
            playerToggle:0,
//--
            // Set this to the first player number!
//            selectedPlayer: 0,
            recommendationId: 0,

            icons: [
                "mdi-numeric-0-circle", 
                "mdi-numeric-1-circle", 
                "mdi-numeric-2-circle", 
                "mdi-numeric-3-circle", 
                "mdi-numeric-4-circle", 
                "mdi-numeric-5-circle"
                ],
            iconColor: [
                "gray",
                "red",
                "orange",
                "yellow",
                "lime",
                "light-green accent-3"
            ],
            progressColor : [
                "red",
                "yellow",
                "yellow",
                "light-green accent-3",
                "light-green accent-3"
            ],
            menuItems:[
                { text: "Stats", key: "1", value: 'tab-1', href: "#tab-1", admin: false, context: ['individual', 'group']},
                { text: "Attributes", key: "2", value: 'tab-2', href: "#tab-2", admin: false, context: ['individual']},
                { text: "Comments", key: "3", value: 'tab-3', href: "#tab-3", admin: false, context: ['individual', 'group']},
                { text: "Analysis", key: "4", value: 'tab-4', href: '#tab-4', admin:false, context: ['individual','group']},
                { text: "Team Recommendations", key: "5", value: 'tab-5', href: "#tab-5", admin: false, context: ['individual']},
                { text: "Application Data", key: "6", value: 'tab-6', href: "#tab-6", admin: false, context: ['individual']},

            ],
        }
    }
}
</script>

<style scoped>
.row{
    margin:0px;
    padding:0px;
}

.sm-criteria-container{
    width:80%;
    padding-left:5%;
}
.small-name{
    margin-top:0px;
    margin-bottom: 0px !important;
    padding-top:0;
    padding-bottom: 0px;
    text-align:right;
    font-size:5pt;
}
.name{
    margin-top:0px;
    margin-bottom: 0px !important;
    padding-top:0;
    padding-bottom: 0px;
    text-align:right;
}

.name-number{
    font-weight:900;
    font-size:medium;
}
.custom-tooltip {
    opacity: 1!important;
}

.playercolumn {
    margin-top: 0px;
    margin-bottom: auto;
}
.selectionpanel {
    margin-top: 0px;
    margin-bottom: auto;
}
.v-btn-toggle{
    flex-direction: row;
    flex-wrap: wrap;
}
.v-btn--active{
    border-color: blue;
    border-style: solid;
    border-radius: 5px;
    border-width: 10px;
}

.playerbox {
    background: blue;
    border-radius: 10px;
}
.playerbox-mobile {
    background: blue;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-right:10px;
}
.selected-label {
    font-size: 10px;
    color: white;
    font-weight: 600;
}

.selected-number {
    color: white;
    font-weight: 600;
    font-size: 30px;

}

.tryouttitlecontainer {
    padding: 0px !important;
}

.tryouttitle {
    font-size: 20px;
    font-weight: 900;
    padding: 0px;

}

.playerfirstname {
    font-size: 15px;
    font-weight: 900;
    color: white;
    background: #028385;
}
.playerfirstname-mobile {
    font-size: 15px;
    font-weight: 900;
    color: white;
}
.playerlastname {
    font-size: 15px;
    color: white;
    background: #028385;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.playerlastname-mobile {
    font-size: 15px;
    color: white;
}
.loading .spinner {
  border-top-color: theme('colors.red.700');
}
.loading .spinner {
  -webkit-animation: spinner 1s linear infinite;
  animation: spinner 1s linear infinite;
}
@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.refresh {
    display: flex;
    justify-content: right;
}

.refresh-container {
    justify-content:right !important;
    padding-top:10px !important;
    padding-bottom: 10px !important;
}

.row .refresh {
    justify-content: right !important;
}

.player-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.attribute-boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
}

.attribute-box {
    display: flex;
    padding: 10px;
}

.v-btn.v-size--default.players, .v-btn.v-size--large.players {
    font-size: 1.5rem;
    width: 2rem;
    /* padding: 49px; */
    margin: 5px;
}

.confidence-data {
    color: white;
}

.v-btn-toggle > .v-btn.v-btn--active {
    border-style: solid!important;
    border-radius: 20px!important;
}

.no-border{
    margin: 0px;
    padding: 0px;
}

.bottom-space{
    padding-bottom:20px;
}

.small-switch{
    font-size: 0.5rem
}

.v-input{
    margin:0px;
    padding:0px;
}

.active-button{
    padding-left:0px;
    padding-right:0px;
}

.tab-select{
    margin-left: 10px;
    max-width:95vw;
}

.skill-container{
/*    height:100vh;*/
}

.tab-row-l {
 /*   overflow-y:scroll;*/
    height:50%;
}
.tab-row {
 /*   overflow-y:scroll;*/
    height:70%;
    margin:5px 15px 5px 0px;
}

.no-parts{
    border-style:solid;
    border-radius:10px;
    padding:20px;
    margin:30px;
}

.no-group-message{
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: large;
    padding-top: 20px;
}
</style>
import Vue from 'vue'
import App from './app/App.vue'
import DateTimePicker from 'vuetify-datetime-picker'
import responsive from 'vue-responsive'

import { store } from './_store';
import { router } from './_helpers'
// eslint-disable-next-line
import { Validator, ValidationProvider, extend, ValidationObserver} from 'vee-validate';
import { size, min, max, integer , numeric} from 'vee-validate/dist/rules'

// VueSockets


import VueSocketIO from 'vue-socket.io'
import {io} from 'socket.io-client';

const WS_LINK=process.env.VUE_APP_WS_LINK

var options={
  auth: {
    token: localStorage.getItem('jwt')
  }
}

if(process.env.VUE_APP_WS_OPTIONS===1){
  options.transports = ['websocket']
} 

  

Vue.use(new VueSocketIO({
//  debug:true,
  connection: io(WS_LINK,options),
  vuex:{
    store,
    actionPrefix: "SOCKET_",
    mmutationPrefix: 'SOCKET_',
  },

}))

localStorage.setItem('networkWarning',false)

// MDI Icons
import mdiVue from 'mdi-vue/v2'
import * as mdijs from '@mdi/js'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

import './assets/css/main.css'

import {PhoneNumberUtil, PhoneNumberFormat as PNF} from 'google-libphonenumber'

import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

const toastOptions = {
  filterBeforeCreate: (toast, toasts)=>{
    for(var idx in toasts){
      if(toasts[idx].id === toast.id){
        return false
      }
    }
    return toast
  }
}
Vue.use(Toast, toastOptions)

import moment from 'moment'
import zxcvbn from 'zxcvbn';
import pluralize from 'pluralize';

Vue.prototype.moment= moment
Vue.prototype.$pluralize= pluralize

Vue.config.productionTip = false

Vue.use(DateTimePicker)
Vue.use(responsive)


Vue.use(mdiVue, {
  icons: mdijs
})


Vue.component('auth-fail', () => import ('@/components/AuthFail'))
Vue.component('loading', () => import ('@/components/loading'))

Vue.mixin({
  methods: {
    clearPaneHistory(){
      this.$store.dispatch('nagivatorStore/clearPaneHistory')
    },
    $acronize(str){
      var parts = str.split(' ')
      var initials = ''
      for (var i = 0; i < parts.length; i++) {
        if (str[i].length > 0 && str[i] !== '') {
          initials += str[i][0]
        }
      }
      return initials
    },
    $capitalize(str){
      // Fist break it into words
      const lcString = str.toLowerCase()
      const words = lcString.split(' ')

      // CApitalise each word
      for(var i=0;i< words.length;i++){
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
      }

      // Rejoin the string

      return words.join(' ')
    },
    authorizedForEvent(auths){
      var event = null
      
      if(Object.prototype.hasOwnProperty.call(this.$store.state.tryoutStore.data, this.$store.state.navigatorStore.selectedTryout)){
        event = this.$store.state.tryoutStore.data[this.$store.state.navigatorStore.selectedTryout]
      } else {
        event = this.$store.state.teamStore.data[this.$store.state.navigatorStore.selectedTryout]
      }
      if(event){
        for(var idx in auths){
          if(auths[idx]==="admin" && event.admin) return true
          if(auths[idx]==="selector" && event.selector) return true
          if(auths[idx]==="owner" && event.owner) return true
          if(auths[idx]==="athlete" && event.athlete) return true
          if(auths[idx]==="support" && event.support) return true
          if(auths[idx]==="headcoach" && event.headcoach) return true
          if(auths[idx]==="assistantcoach" && event.assistantcoach) return true
          if(auths[idx]==="programcoach" && event.programcoach) return true
        }
      }
      return false
    },
    eventAllows(auths){
      var event = null
      
      if(Object.prototype.hasOwnProperty.call(this.$store.state.tryoutStore.data, this.$store.state.navigatorStore.selectedTryout)){
        event = this.$store.state.tryoutStore.data[this.$store.state.navigatorStore.selectedTryout]
      } else {
        event = this.$store.state.teamStore.data[this.$store.state.navigatorStore.selectedTryout]
      }
      if(event){
        for(var idx in auths){
          if(auths[idx]==="admin" && event.admin) return true
          if(auths[idx]==="selector" && event.selector) return true
          if(auths[idx]==="owner" && event.owner) return true
          if(auths[idx]==="athlete" && event.athlete) return true
          if(auths[idx]==="support" && event.support) return true
          if(auths[idx]==="headcoach" && event.headcoach) return true
          if(auths[idx]==="assistantcoach" && event.assistantcoach) return true
          if(auths[idx]==="programcoach" && event.programcoach) return true
        }
      }
      return false
    }
  }
})

extend('required', {
  validate(value) {

    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1 
    }

  },
  computedRequired: true,
  message: 'This question must be answered'

})
extend('nonEmptyArray', {
  params: ['target'],
  validate(v, {target}){
    return {
      required: true,
      valid: !!(target && target.length) || 'Item is required'
    }
  },
  computesRequired: true,
})

extend('strength', {
  params: ['min'],
  validate(value, {min}){
    return {
      required: true,
      valid: value && zxcvbn(value).score>min 
    }
  },
  message: 'Please use a stronger password. Try a mix of letters, numbers and/or symbols'

})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend('emailconfirm', {
  params: ['target'],
  validate(value, {target}){
    return value === target

  },
  message: 'Email address confirmation does not match'
})

extend('permanentDelete', {
  validate(value){
    return value==='permanently delete'
  },
  message: 'Type "permanently delete" to acknowledge and delete'
})

extend('nonEmptyObject', {
  validate(value){
    return Object.keys(value).length >0
  }
})

extend('validFeeTitle', {
  validate(value, fee){
    if(fee !== 0){
      return value.length >0
    } else 
      return true
  }
})

extend('set', {
  validate(value) {
    return value==true
  }
})

function validatePhoneNumber(value, alphaCode) {

  if(!value || value.length  <2 ){
    return
  }
  const phoneUtil = PhoneNumberUtil.getInstance()

  const number = phoneUtil.parse(value,alphaCode)
  phoneUtil.format(number, PNF.INTERNATIONAL)
  if(phoneUtil.isValidNumber(number, alphaCode)){
    return true
  } else {
    return false
  }
}

extend ('size', size)
extend('min', min)
extend('max', max)
extend('integer', integer)
extend('numeric', numeric)

extend('mobileNumber', {
  params: ['code'],
  validate(value, {code}) {
    let telResult
    telResult = validatePhoneNumber(value,code)
    return telResult
  }
})

extend ('fileSize', {
  params: ['size'],
  validate(value, {size}) {
    return value.size <= size
  }
})


extend('notEmpty', {
  validate(value){
    return value.length>0
  }
})


new Vue({
  el: '#app',
  template: '<App/>',
  beforeCreate() {
    this.$store.commit('navigatorStore/initialiseNavigator')
    this.$store.dispatch('globalsettingsStore/load')
    this.$store.dispatch('sportStore/findAll')
  },
  sockets: {
    connect(){
      this.$store.dispatch('navigatorStore/setNetworkStatus', true)
    },
    disconnect(){
      this.$store.dispatch('navigatorStore/setNetworkStatus', false)
    }
  },

  components: {
    App,
    ValidationProvider,
    ValidationObserver,
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
